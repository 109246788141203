<template>
	<div class="clinic-settings">
		<p class="title">Configurações da Clínica</p>

		<div class="container-data">
			<div class="tabs">
				<span v-can="'CcGer1'" class="tab" :class="{ 'active': isTabGeneral }" @click="changeTab('geral')">
					Geral
				</span>

				<span v-can="'CcConv1'" class="tab" :class="{ 'active': isTabInsurances }" @click="changeTab('convenio')">
					Convênios
				</span>

				<span class="tab" :class="{ 'active': isTabTables }" @click="changeTab('tabelas')">
					Tabelas
				</span>
				
				<span class="tab" :class="{ 'active': isTabEquipment }" @click="changeTab('equipamentos')">
					Equipamentos
				</span>

				<span v-can="'CcSal1'" class="tab" :class="{ 'active': isTabRooms }" @click="changeTab('salas')">
					Salas e setores
				</span>

				<span v-can="'CcConvPro1'" class="tab" :class="{ 'active': isTabProfessionals }" @click="changeTab('profissional')">
					Profissionais
				</span>

				<span v-can="'CcGer1'" class="tab" :class="{ 'active': isTabPrint }" @click="changeTab('impressao')">
					Impressão
				</span>

				<span v-can="'CcUpArq1'" class="tab" :class="{ 'active': isTabUpload }" @click="changeTab('upload')">
					Upload de arquivos
				</span>

				<!-- @todo inserir permissão -->
				<span v-show-feature="'payment-gateway'" class="tab" :class="{ 'active': isTabSubscription }" @click="changeTab('subscription')">
					Plano e cobrança
				</span>

        		<span v-if="showOrientationsTab" class="tab" :class="{ 'active': isTabOrientations }" @click="changeTab('orientacoes')">
					Orientações
				</span>

				<span class="tab" :class="{'active': isTabIntegrations}" @click="changeTab('integrations')" v-if="showIntegrationsTab">
					Integrações
				</span>

        <span class="tab" :class="{ 'active': isTabRegister }" @click="changeTab('cadastro')">
					Cadastro
				</span>

      		</div>

          <div class="body flex-fill">
            <ClinicDataView v-if="isTabGeneral" />
            <Tables v-if="isTabTables"/>
            <MedicalInsurancesView v-if="isTabInsurances" />
            <EquipmentsView v-if="isTabEquipment" />
            <RoomsView v-if="isTabRooms" />
            <ProfessionalView v-if="isTabProfessionals" />
            <PrintableConfigs v-if="isTabPrint" />
            <UploadView v-if="isTabUpload" />
            <SubscriptionView v-if="isTabSubscription" />
            <OrientationsView v-if="isTabOrientations" :type="'clinic'"/>
            <IntegrationsView v-if="isTabIntegrations" />
            <RegisterView v-if="isTabRegister" />
          </div>
		</div>
	</div>
</template>

<script>
import brazilStates from '@/utils/states'
import { showFeature } from '@/utils/permissionsHelp'
import { userHasPermission } from '@/utils/localStorageManager'

export default {
	name: 'ClinicSettingsView',
	metaInfo: {
		title: 'Eyecare - Configurações da Clínica'
	},
	components: {
		ClinicDataView: () => import('./ClinicDataView'),
		Tables: () => import('@tables/views/Tables'),
		MedicalInsurancesView: () => import('./MedicalInsurancesView'),
		ProfessionalView: () => import('./ProfessionalView'),
		PrintableConfigs: () => import('./PrintableConfigs'),		
		UploadView: () => import('./UploadView'),
		RoomsView: () => import('./RoomsView'),
		SubscriptionView: () => import('./SubscriptionView.vue'),
		EquipmentsView: () => import('./EquipmentsView.vue'),
    OrientationsView: () => import('./OrientationsView.vue'),
		IntegrationsView: () => import('./IntegrationsView.vue'),
    RegisterView: () => import('./RegisterView.vue'),
	},
	data() {
		return {
			tabUrl: this.$route.params.tab,
			currentTab: 'general',
			parseTab: {
				geral: 'general',
				tabelas: 'tables',
				convenio: 'medical-insurances',
				equipamentos: 'equipments',
				salas: 'rooms',
				profissional: 'professionals',
				impressao: 'print',
				upload: 'upload',
				plano: 'subscription',
        orientacoes: 'orientations',
				integrations: 'integrations',
        cadastro: 'cadastro'
			},
			statesOptions: brazilStates.map((state) => state.initial),
      showOrientationsTab: userHasPermission('OrCli1') || userHasPermission('OrCli2')
		}
	},
	computed: {
		isTabGeneral() { return this.currentTab === 'general' },
		isTabTables() { return this.currentTab === 'tables' },
		isTabInsurances() { return this.currentTab === 'medical-insurances' },
		isTabEquipment() { return this.currentTab === 'equipments' },
		isTabRooms() { return this.currentTab === 'rooms' },
		isTabProfessionals() { return this.currentTab === 'professionals' },
		isTabPrint() { return this.currentTab === 'print' },
		isTabUpload() { return this.currentTab === 'upload' },
		isTabSubscription() { return this.currentTab === 'subscription' },
		isTabOrientations() { return this.currentTab === 'orientations' },
		isTabIntegrations() {return this.currentTab === 'integrations' },
    isTabRegister() { return this.currentTab === 'cadastro' },
		showIntegrationsTab() {
			return this.showFeature('Integração com Labpacs') || this.showFeature('Integração com Essilor')
		},
	},
	mounted() {
		this.currentTab = this.$route.params.tab ? this.parseTab[this.$route.params.tab] : 'general'
	},
	methods: {
		showFeature,
		changeTab(tab){
			this.currentTab = this.parseTab[tab] ?? 'general'
			window.history.pushState(
				null,
				null,
				`/configuracoes-da-clinica/${tab}`
			)
		}
	}
}
</script>

<style lang="scss">
.clinic-settings {
	min-height: 100%;
	padding: 30px 24px;
	text-align: left;

	.title {
		font-family: 'Red Hat Display';
		font-weight: 700;
		font-size: 24px;
		color: var(--dark-blue);
		margin-bottom: 27px;
	}

	.container-data {
		width: 100%;
		height: 100%;
		background-color: var(--neutral-000);
		border-radius: 8px;
		display: flex;
		flex-direction: column;

		.tabs {
			height: 72px;
			border-bottom: 1px solid var(--neutral-200);
			display: flex;
			align-items: center;

			.tab {
				font-weight: 600;	
				color: var(--type-active);
				padding: 22px 24px;
				cursor: pointer;
				border-bottom: 3px solid var(--neutral-000);
				max-width: 30ch;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&.active {
					color: var(--orange);
					border-bottom: 4px solid var(--orange);
				}

			}
			@media (max-width: 1380px) {
				.tab { font-size: 0.8rem; }
			}
		}

		.body {
			padding: 24px;

			.title {
				font-family: 'Red Hat Display';
				font-weight: 700;
				font-size: 18px;
				color: var(--dark-blue);
				margin-bottom: 24px;
			}

			.table {
				width: 100%;
				display: table;

				thead {
					th {
						font-weight: 700;
						font-size: 14px;
						color: var(--type-active);
						border-top: 0;
						padding: 4px 0;
						border-bottom: 2px solid var(--neutral-300);
					}
				}

				tbody {
					tr {
						border-bottom: 1px dashed var(--neutral-300);
					}

					td {
						font-weight: 400;
						font-size: 16px;
						color: var(--type-active);
						border-top: 1px dashed var(--neutral-300);
						padding: 16px 0;

						.more-with-menu {
							width: 24px;
							height: 28px;
							position: relative;
							display: inline-block;

							.more-icon {
								width: 24px;
								height: 24px;
								stroke: var(--neutral-500);
								transition: all 0.5s;
								cursor: pointer;
							}

							.menu {
								width: 233px;
								position: absolute;
								top: 28px;
								right: 0;
								background: var(--neutral-000);
								border: 1px solid var(--neutral-200);
								box-sizing: border-box;
								box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
								border-radius: 8px;
								padding: 24px;
								opacity: 0;
								transition: all 0.5s;
								flex-direction: column;
								align-items: start;
								display: none;

								.mtb-12 {
									margin: 12px 0;
								}

								.btn {
									font-weight: 600;
									margin-bottom: 16px;

									&.remove {
										color: var(--states-error);
									}
								}
							}

							&:hover {
								.more-icon {
									transform: rotate(90deg);
								}

								.menu {
									opacity: 1;
									display: flex;
									z-index: 10;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
