import Vue from 'vue'
import router from '../../router'
import { filledModules } from '@/utils/documentHelper'
import { addAdditionalExamsToForm, summaryTypes, populateMedicalRecords, gotToOption } from '@/utils/attendanceHelper'
import { init, preAuthorizeSignatures, readCertificate } from '@/utils/lacuna/lacunaHelper'
import { trackFinishedAttendanceEvents } from '@/utils/mixpanelProcess'
import { removeAdditionalExamModule } from '@/store/registerModule'

import menu from './menu'
import form from './form/index'
import examPackageRequest from './form/examPackageRequest'
import history from './history'
import filesModal from './filesModal'
import medicalRecordsStore from './medicalRecordsImport'
import { cpf } from 'cpf-cnpj-validator'
import eyeExams from './eyeExams'

const attendanceStore = {
  namespaced: true,
  state: () => ({
    canEdit: true,
    canDefaultForms: false,
    customFormEdit: false,
    attendance: null,
    preConsultation: null,
    exam: null,
    loading: false,
    cidConsultation: null,
    bvConsultation: null,
  }),
  getters: {
    attendance: state => {
      if(state.attendance) {
        state.canDefaultForms = false
      }

      return state.attendance
    },
    hasPreConsultation: state => {
      return !!(
        state.preConsultation?.medical_records?.length
      )
    },
    preConsultation: state => {
      return state.preConsultation
    },
    cidConsultation: state => {
      return state.cidConsultation
    },
    bvConsultation: state => {
      return state.bvConsultation
    },
    hasExam: state => {
      return Boolean(
        state.exam?.medical_records?.length && state.attendance?.type === 'ATTENDANCE'
      )
    },
    exam: state => {
      return state.exam
    }
  },
  mutations: {
    setCustomFormEdit(state, value) {
      state.customFormEdit = value
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    clearAttendance(state) {
      state.canEdit = true
      state.attendance = null
      Object.keys(state.form).map(key => {
        if (key.includes('exame-personalizado')) {
          removeAdditionalExamModule(key)
          return
        }
        if (state.form[key] && state.form[key].id) {
          state.form[key].summary !== undefined && delete state.form[key].summary
          state.form[key].completed = false
          state.form[key].multiple  ?
            (state.form[key].value = []) :
            Object.keys(state.form[key].fields).map(field => {
              const value = state.form[key].fields[field]
              state.form[key].fields[field] = Array.isArray(value) ? [] : (value === false || value === true ? value : null)
            })
        }
      })
    },
    setAttendance(state, payload) {
      state.canEdit = ['OPENED', 'PAUSED'].includes(payload.status)
        || payload?.type === 'EXAM' && payload.status === 'PENDING'
        || (payload.status === 'CLOSED' && payload.type === 'MEDICAL_REPORT')
      state.attendance = payload
    },
    setPreConsultation(state, payload) {
      state.preConsultation = payload
    },
    setCidConsultation(state, payload) {
      state.cidConsultation = payload
    },
    setBvConsultation(state, payload) {
      state.bvConsultation = payload
    },
    setExam(state, payload) {
      state.exam = payload
    },
    closeAttendance(state) {
      state.canEdit = false
    },
    editDefaultForms(state) {
      state.canDefaultForms = true
    },
    editAttendance (state) {
      state.canEdit = true
    },
    setAttendanceEndDatetime (state) {
      // Não atualizar data de edição pré-consulta
      Vue.prototype.$set(state.attendance, 'end_datetime', state.attendance?.end_datetime ?? Vue.prototype.moment())
    },
    async populateMedicalRecords(state) {
      if (!state.attendance) return
      state.loading = true
      try {
        addAdditionalExamsToForm(state.attendance)
        const res = await Vue.prototype.api.getPatientSummary(state.attendance?.patient.id, summaryTypes)
        const form = populateMedicalRecords(state.canEdit, state.attendance, state.form, res.data)
        state.form = form
        Object.keys(form).map(key => {
          if (form[key] && form[key].id && form[key].summary) {
            this.dispatch(`attendance/form/updateMedicalRecord`, form[key], { root: true })
          }
        })
        state.loading = false
      } catch(err) {
        state.loading = false
        console.log(err)
      }
    },
    async getTreatmentRequests(state) {

      const professional = state.attendance?.professional?.id
      const { data } = await Vue.prototype.api.getForms(state.attendance.clinic.id, professional, state.attendance.type)     
      this.forms = data
      const options = data
      .filter(form => form.active)
      .map(form => ({ label: form.name, value: form.id, forms: form.forms, type: form.type }))
      const form = options.find(f => f.value === state.attendance.form_id)
      
      if(form.label !== 'Programa Glaucoma SUS') {
        if (!state.attendance?.patient?.id) return
        state.loading = true
        try {
          const response = await Vue.prototype.api.findLastMedicalRecord('solicitacao-de-tratamento', state.attendance?.patient?.id)
          if (state.canEdit && state.attendance.type === 'ATTENDANCE' && response.data?.value) {
            state.form.solicitacaoDeTratamento = {
              ...state.form.solicitacaoDeTratamento,
              completed: true,
              showing: true,
              opened: true,
              value: response.data?.value
            }
            this.dispatch(`attendance/form/updateMedicalRecord`, state.form.solicitacaoDeTratamento, { root: true })
          }
        } catch(err) {
          console.log(err)
        } finally {
          state.loading = false
        }
      }
    }
  },
  actions: {
    setCustomFormEdit(context, payload) {
      context.commit('setCustomFormEdit', payload)
    },
    closeAttendance(context) {
      context.commit('closeAttendance')
    },
    editDefaultForms(context) {
      context.commit('editDefaultForms')
    },
    clearPreConsultation ({commit, state}) {
      commit('setPreConsultation', null)
    },
    async getPreConsultation({commit, state}) {
      commit('setLoading', true)
      try {
        const res = await Vue.prototype.api.getPatientLastPreConsultation(state.attendance.patient.id)
        commit('setPreConsultation', res.data)
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      } finally {
        commit('setLoading', false)
      }
    },
    async getCidConsultation({commit, state}) {
      commit('setLoading', true)
      try {
        const res = await Vue.prototype.api.getPatientLastCidConsultation(state.attendance.patient.id)
        commit('setCidConsultation', res.data)
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      } finally {
        commit('setLoading', false)
      }
    },
    async getBvConsultation({commit, state}) {
      commit('setLoading', true)
      try {
        const res = await Vue.prototype.api.getBVClinicalRecord(state.attendance?.patient?.id)
        commit('setBvConsultation', res.data)
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      } finally {
        commit('setLoading', false)
      }
    },
    clearExam({ commit }) {
      commit('setExam', null)
    },
    async findExam({ commit, state }) {
      commit('setLoading', true)
      try {
        const { data } = await Vue.prototype.api.findPatientLastExam(state.attendance?.patient?.id)
        commit('setExam', data)
      } catch (err) {
        Vue.prototype.$toast.error(err.message)
      } finally {
        commit('setLoading', false)
      }
    },
    async getAttendance(context, { clinicId, patientId, attendanceId }) {
      context.commit('clearAttendance')
      context.dispatch('clearExam')
      context.commit('setLoading', true)
      try {
        const res = await Vue.prototype.api.getAttendance(clinicId, patientId, attendanceId)
        const attendance = res.data
        attendance.medicalRecords.map(res =>
          res.type === 'precricao-medicamentosa' &&
          (res.type = 'prescricao-medicamentosa-v2')
        )
        context.commit('setAttendance', attendance)
        if (attendance?.type === 'ATTENDANCE' || attendance?.type === 'BV_PRESCRIPTION' || attendance?.type === 'BV_EXAM' || attendance?.type === 'SURGERY') {
          await context.dispatch('getPreConsultation')
          await context.dispatch('getCidConsultation')
          await context.dispatch('findExam')
          if (attendance?.type === 'BV_EXAM') {
            await context.dispatch('getBvConsultation')
          }
        }
        else if(attendance.type === 'PRE_CONSULTATION') {
          context.dispatch('clearPreConsultation')
        } else if (attendance.type === 'EXAM') {
          context.dispatch('clearExam')
        }
        if (attendance.status === 'PAUSED') {
          await Vue.prototype.api.unpauseAttendance(clinicId, attendanceId)
        }
      } catch (err) {
        context.commit('setLoading', false)
        Vue.prototype.$toast.error(err.message)
      }
    },
    populateMedicalRecords(context) {
      context.commit('populateMedicalRecords')
    },
    getTreatmentRequests(context) {
      context.commit('getTreatmentRequests')
    },

    async finishAttendance(context, payload) {
      const cpfAcompanhante = context?.state?.form?.atestadosMedicos?.fields?.cpfAcompanhante
      const cpfPaciente= context?.state?.form?.atestadosMedicos?.fields?.cpfPaciente
      
      if ((cpfAcompanhante && !cpf.isValid(cpfAcompanhante)) || (cpfPaciente && !cpf.isValid(cpfPaciente)) && !payload.quickPrint)
      {
        Vue.prototype.$toast.warning('CPF informado não é válido')
        this.dispatch(`attendance/form/handleActiveModule`, 'atestadosMedicos', { root: true })
        return false
      }

      if (
          !context.state.form.cid.value.length
          && !payload.quickPrint && !payload.noCid
          && ['ATTENDANCE', 'SURGERY'].find(type => type === context.state?.attendance?.type)
         )
      {
        Vue.prototype.$toast.warning('Informe um CID antes de finalizar')
        this.dispatch(`attendance/form/handleActiveModule`, 'cid', { root: true })
        gotToOption('cid')
        return false
      }
      const toSignDefault = !payload.noCid ? [0] : []
      let docsToSign = [];
      const certificate = context.rootGetters.certificate
      if (certificate && certificate.active  === true) {
        docsToSign = payload.toSign || [...toSignDefault, ...filledModules(context.state.form)];
      } 
      const data = {
        attendanceId: context.state.attendance.id,
        toPrint: payload.toPrint || [],
        toSign: docsToSign,
        quickPrint: payload.quickPrint || false,
        certContent: null,
        service: null,
        tabId: window.sessionStorage.getItem('tabId'),
        reproc: payload.reproc ?? false
      }
      if (!data.quickPrint) {
        if(context.state.attendance.type !== 'PRE_CONSULTATION'){
          if(certificate.active && certificate.type === 1){
            await preAuthorizeSignatures(certificate.cert.thumbprint, data.toSign.length);
            data.certContent = await readCertificate(certificate.cert.thumbprint);
          } else if (certificate.active && certificate.type === 2) {
            data.service = certificate.cert.name;
          }
        }
      }

      const loading = Vue.prototype.$loading.show()
      try {
        const res = await Vue.prototype.api.finishAttendance(data);

        if (res.data.authentication === 'oauth') {
          window.open(res.data.url, '_blank');
        } else if (res.data.authentication === 'rest') {
          for (const signature of res.data.signatures) {
            await init(signature.token, certificate.cert.thumbprint);
          }
          await Vue.prototype.api.restFinishAttendance(data.attendanceId);
        }

        if (!data.quickPrint) {

          switch (context.state.attendance.type) {
            case 'PRE_CONSULTATION':
              await Vue.prototype.api.simpleFinishPreAttendance(data.attendanceId);
              break
            default: 
              await Vue.prototype.api.simpleFinishAttendance(data.attendanceId);
              break;
           }

          context.commit('closeAttendance')
          context.commit('setAttendanceEndDatetime')
          Vue.prototype.$toast.success('Atendimento finalizado com sucesso')
        }

        trackFinishedAttendanceEvents(context.state.attendance, context.state.form, data)
        loading.hide()

        if (!data.quickPrint && context.state.attendance.type === 'ATTENDANCE') {
          router.push(`/visao-geral`)
        } else if (context.state.attendance.type === 'PRE_CONSULTATION') {
          router.push(`/pre-consulta`)
        } else if (!data.quickPrint && context.state.attendance.type === 'EXAM') {
          router.push(`/exames`)
        }
        return true
      } catch (err) {
        loading.hide()
        Vue.prototype.$toast.error(err.message);
      }
    },
    clearAttendance(context){
      context.commit('clearAttendance')
    },
    editAttendance ({commit, state}) {
      if (!state.attendance?.id) return;

      commit('editAttendance')
      Vue.prototype.api.openAttendance(state.attendance.id)
    },
  },
  modules: {
    menu,
    form,
    history,
    filesModal,
    medicalRecordsStore,
    examPackageRequest,
    eyeExams,
  }
}

export default attendanceStore
