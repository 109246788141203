import { updateAttendanceForm } from '@/utils/attendanceFormHelper'

const store = {
  namespaced: true,
  state: () => ({
    id: 'topografia',
    name: 'Topografia',
    opened: true,
    showing: true,
    completed: false,
    multiple: false,
    scopes: ['ATTENDANCE', 'SURGERY', 'EXAM', 'ESSILOR_ATTENDANCE', 'PRE_CONSULTATION'],
    fields: {
      aparelho: null,
      mostrarSimplificada: false,
      olhoDireito: null,
      olhoDireitoK1Curvatura: null,
      olhoDireitoK1Raio: null,
      olhoDireitoK1Eixo: null,
      olhoDireitoK2Curvatura: null,
      olhoDireitoK2Raio: null,
      olhoDireitoK2Eixo: null,
      olhoDireitoKmaxCurvatura: null,
      olhoDireitoKmaxRaio: null,
      olhoDireitoKmaxEixo: null,
      olhoDireitoCilindro: null,
      olhoDireitoKmedio: null,
      olhoDireitoKmedioCurvatura: null,
      olhoDireitoKpi: null,
      olhoDireitoAnguloNasal: null,
      olhoDireitoAnguloTemporal: null,
      olhoDireitoAnguloKappa: null,
      olhoDireitoDiametroPupila: null,
      olhoDireitoDiametroCornea: null,
      olhoDireitoEspessuraCentralCornea: null,
      DireitoImpressao: null,

      olhoEsquerdo: null,
      olhoEsquerdoK1Curvatura: null,
      olhoEsquerdoK1Raio: null,
      olhoEsquerdoK1Eixo: null,
      olhoEsquerdoK2Curvatura: null,
      olhoEsquerdoK2Raio: null,
      olhoEsquerdoK2Eixo: null,
      olhoEsquerdoKmaxCurvatura: null,
      olhoEsquerdoKmaxRaio: null,
      olhoEsquerdoKmaxEixo: null,
      olhoEsquerdoCilindro: null,
      olhoEsquerdoKmedio: null,
      olhoEsquerdoKmedioCurvatura: null,
      olhoEsquerdoKpi: null,
      olhoEsquerdoAnguloNasal: null,
      olhoEsquerdoAnguloTemporal: null,
      olhoEsquerdoAnguloKappa: null,
      olhoEsquerdoDiametroPupila: null,
      olhoEsquerdoDiametroCornea: null,
      olhoEsquerdoEspessuraCentralCornea: null,
      EsquerdoImpressao: null
    }
  }),
  mutations: {
    handleProps: (state, { key, value }) => {
      state[key] = value
    },
    handleFields: (state, { key, value }) => {
      state.fields[key] = value
    },
    checkComplete: state => {
      state.completed = Object.keys(state.fields).some(key => state.fields[key])
    }
  },
  getters: {},
  actions: {
    handleProps(context, { key, value }) {
      context.commit('handleProps', { key, value })

      if (
        context?.rootState?.attendance?.attendance?.id &&
        ['showing', 'opened'].includes(key)
      ) {
        const form = context?.rootGetters['attendance/form/getSelectedForm']
        const forms = form.forms
        const index = forms?.findIndex(form => {
          return form.id === context.state.id
        })

        if (index && index !== -1) {
          forms[index][key] = value
          form.forms = forms
          updateAttendanceForm(
            context?.rootState?.attendance?.attendance?.attendance_form?.id,
            { forms: forms }
          )
          context.dispatch(`attendance/form/updateAttendanceForms`, form, {
            root: true
          })
        }
      }
    },
    handleFields(context, { key, value }) {
      context.commit('handleFields', { key, value })
      context.commit('checkComplete')
    }
  }
}

export default store