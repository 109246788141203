/* eslint-disable */
import axios from 'axios'

import {
  getAccessToken,
  setAccessToken,
  setRefreshToken,
  setCurrentUser,
  getCurrentClinic,
  setCurrentClinic,
  setPermissions,
  clearLocalStorage,
} from '@/utils/localStorageManager'

const BASE_URL = process.env.VUE_APP_API_URL + 'api/';

const GLAUCO_BASE_URL = process.env.VUE_APP_GLAUCO_API_URL;

const BASE_URL_BV = process.env.VUE_APP_BV_API_URL;

const accessToken = localStorage.getItem('access_token')

export const base = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken
    }
})

export const baseBV = axios.create({
  baseURL: BASE_URL_BV,
  headers: {}
})

export const baseXlsx = axios.create({
  baseURL: BASE_URL,
  accept: 'application/octet-stream',
  responseType: 'blob',
  headers: {
      Authorization: 'Bearer ' + accessToken
  }
})

export const baseFile = axios.create({
  baseURL: BASE_URL,
  accept: '*',
  responseType: 'blob',
  headers: {
      Authorization: 'Bearer ' + accessToken
  }
})

export const baseProd = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + accessToken
    }
})

export const baseUpload = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + accessToken
    }
})

export const base2 = axios.create({

    baseURL: 'https://api.eyecarehealth.com.br/docs/plain/public/',
    headers: {
        Accept: 'application/json',
    }
})


export const base3 = axios.create({

    baseURL: BASE_URL + 'integration/',
    headers: {
        'api-key': 'WQiOiJjNDk0ZmJmOS00ZjY1LTQyYTctYjY1ZC1mMDcxODdkZjA3ODUiLCJkYXRhIjp7',
    }
})

export const glaucoApi = axios.create({
    baseURL: BASE_URL + 'glauco',
  })

export const glaucoApiAdonis = axios.create({
    baseURL: GLAUCO_BASE_URL,
    headers: {
        'api-key': 'WQiOiJjNDk0ZmJmOS00ZjY1LTQyYTctYjY1ZC1mMDcxODdkZjA3ODUiLCJkYXRhIjp7',
        Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJlNGE2YjRjMS02MWZjLTRhMWItYTQ4Ni1kZTFiMTk3MjRhYjQiLCJkYXRhIjp7ImlkIjoiZTRhNmI0YzEtNjFmYy00YTFiLWE0ODYtZGUxYjE5NzI0YWI0IiwicGljdHVyZSI6bnVsbCwibmFtZSI6IlJpY2hhcmQgTGltYSBEZSBNb3JhZXMiLCJuaWNrbmFtZSI6IlJpY2hhcmQiLCJnZW5kZXIiOiJtYXNjdWxpbm8iLCJjcGYiOiI0NjguMTkwLjg5OC0zMCIsImVtYWlsIjoicmljaGFyZGxpbWE2OTk3QGdtYWlsLmNvbSIsImNlbGxwaG9uZSI6IigxMSkgOTQyNzItMzg3MyIsImJpcnRoZGF5IjoiMjAwMS0wNS0yNlQwMzowMDowMC4wMDBaIiwiaWRlbnRpZnlfYnkiOiJlbGUiLCJyYWNlIjoiYnJhbmNhIiwiY2l0eSI6IlRlc3RlIiwic3RhdGUiOiJBQyIsInRva2VuIjpudWxsLCJsb2dpbl9zb3VyY2UiOm51bGwsImNyZWF0ZWRfYXQiOiIyMDIxLTA3LTIxIDA3OjE0OjUwIiwidXBkYXRlZF9hdCI6IjIwMjEtMDctMjEgMjA6NTM6NDIiLCJkZWxldGVkX2F0IjpudWxsLCJzaGFyZV9jb2RlIjpudWxsfSwiaWF0IjoxNjI3NjY2MzQ2fQ.Hd6bJy_FIALegMLIZ9K-NkcbV9R8gExwzGgVseu0DTU'}
  })

export const glaucoApiUpload = axios.create({
  baseURL: 'https://glauco.eyecarehealth.com.br',
  headers: {
      'api-key': 'WQiOiJjNDk0ZmJmOS00ZjY1LTQyYTctYjY1ZC1mMDcxODdkZjA3ODUiLCJkYXRhIjp7',
      'Content-Type': 'multipart/form-data'}
})

base.interceptors.response.use(null, function(err) {
    if (err.response && err.response.status === 401) {
        clearLocalStorage();
        window.location.href = '/login';
    } else if (err.response && err.response.status === 400 && err.response && err.response.data) {
      return Promise.reject(err.response.data);
    } else if (err.response && err.response.status === 422 && err.response.data && err.response.data.errors) {
      const errors = Object.keys(err.response.data.errors).reduce((acc, key) => {
        acc = acc.concat(err.response.data.errors[key]);
        return acc;
      }, []);
      return Promise.reject({ message: errors.join(' | ') });
    } else if(err.response.status === 422 && err.response?.error?.errors) {
      return Promise.reject({
        message: 'Por favor verificar os campos inválidos.',
        response: err.response
      })
    }
    return Promise.reject({
      message: 'Não foi possível realizar essa ação, caso o problema persista, contate o suporte.',
      response: err.response,
    });
});

base.interceptors.request.use(function (config) {
  if (process.env.NODE_ENV !== 'local') return config;
  if (!config.params) {
    config.params = {};
  }
  config.params['XDEBUG_SESSION_START'] = process.env.VUE_APP_DEBUGGER;
  return config;
}, null);

baseUpload.interceptors.request.use(function (config) {
  if (process.env.NODE_ENV !== 'local') return config;
  if (!config.params) {
    config.params = {};
  }
  config.params['XDEBUG_SESSION_START'] = process.env.VUE_APP_DEBUGGER;
  return config;
}, null);

baseFile.interceptors.request.use(function (config) {
  if (process.env.NODE_ENV !== 'local') return config;
  if (!config.params) {
    config.params = {};
  }
  config.params['XDEBUG_SESSION_START'] = process.env.VUE_APP_DEBUGGER;
  return config;
}, null);


const Api = {
    superUserLogin(data) {
      return base.post(`/superUserLogin`, data)
    },
    getPeopleFromClinic(clinicId) {
      return base.get(`/clinic/${clinicId}/people`);
    },
    getPermissionsNew(grouped) {
      const config = {
        params: { grouped }
      };
      return base.get(`/permissions`, config);
    },
    getPermissionKeys(personId) {
      const config = {
        params: { personId }
      };
      return base.get(`/personPermissions/keys`, config);
    },
    handlePermissions(data) {
      return base.post(`/personPermissions`, data)
    },
    createClinicPerson(data) {
      return base.post(`/clinicPerson`, data)
    },
    updateClinicPerson(id, data) {
      return base.put(`/clinicPerson/${id}`, data)
    },
    deleteClinicPerson(id) {
      return base.delete(`/clinicPerson/${id}`)
    },
    reSendInvite(clinicPersonId) {
      return base.put(`/clinicPerson/${clinicPersonId}/reSendInvite`)
    },
    createExamFile(dataForm, listeners) {
      return baseUpload.post(`/attendanceFiles/examFile`, dataForm, listeners);
    },
    updateExamFile(id, dataForm, listeners) {
      return baseUpload.post(`/attendanceFiles/examFile/${id}`, dataForm, listeners)
    },
    getExamFiles(examId) {
      return base.get(`/attendanceFiles/examFile/${examId}`);
    },
    getByCode(code, pass) {
      return base.get(`/attendance-files/find-by-code`, { params: { code, pass } });
    },
    deleteUnusedFiles(ids) {
      return base.delete(`attendanceFiles/examFile/deleteUnusedGroupedFiles`, { params: { ids } });
    },
    createSurgeryInformationFile(dataForm, listeners) {
      return baseUpload.post(`attendanceFiles/surgeryInformationFile`, dataForm, listeners);
    },
    getDocumentsByPatientAndFilter(patientId, surgeryInformationId, distinctType, filters) {
      return base.get(`attendanceFile/getDocumentsByPatientAndFilter/${patientId}`, {
        params: { surgeryInformationId, distinctType, filters }
      });
    },
    getSurgeryInformationFiles(surgeryInformationId, generalSheetId) {
      return base.get(`attendanceFile/getSurgeryInformationFiles/${surgeryInformationId}`, {
        params: { surgery_record_id: generalSheetId }
      });
    },
    getSurgeryPrintableFiles(surgeryInformationId) {
      return base.get(`surgery/documents/printable/${surgeryInformationId}`);
    },
    bulkCreateSurgeryInformationFiles(dataForm, listeners) {
      return base.post(`surgeryInformationFiles/bulkCreate`, dataForm, listeners);
    },
    deleteSurgeryInformationFileByProps(surgeryInformationId, attendanceFileId) {
      return base.delete(`surgeryInformationFiles/deleteByProps/${surgeryInformationId}`, {
        params: { 'attendance_file_id' : attendanceFileId }
      });
    },
    findSurgeryCompanion(surgeryInformationId) {
      return base.get(`surgeryCompanions/surgeryInformation/${surgeryInformationId}`);
    },
    createSurgeryCompanion(data) {
      return base.post(`surgeryCompanions`, data);
    },
    updateSurgeryCompanion(id, data) {
      return baseUpload.post(`surgeryCompanions/${id}`, data);
    },
    
    getExamByAppointmentAndClinicProcedure(appointmentId, clinicProcedureId) {
      return base.get(`exams/appointment/${appointmentId}/clinicProcedure/${clinicProcedureId}`);
    },
    updateExamAttendance(id, data) {
      return base.put(`examAttendances/${id}`, data);
    },
    getAppointmentExams(params) {
      return base.get('examAttendances', {
        params
      });
    },
    updateAppointmentProcedure(id, data) {
      return base.put(`/appointmentProcedures/${id}`, data);
    },
    getClinicRooms(clinicId, page, query, procedure) {
      const config = {
        params: { clinicId, page, query, procedure }
      }
      return base.get('rooms', config);
    },
    getAllClinicRooms(clinicId) {
      const config = {
        params: { clinicId }
      }
      return base.get(`rooms/${clinicId}/allClinicRooms`, config);
    },
    getRoomsByProcedureCode(clinicId, code) {
      return base.get(`rooms/${clinicId}/${code}/getByProcedureCode`);
    },

    getRoomsByItem(clinicId, itemId) {
      return base.get(`rooms/clinic/${clinicId}/item/${itemId}`);
    },
    createClinicRoom(data) {
      return base.post(`rooms`, data);
    },
    deleteClinicRoom(roomId) {
      return base.delete(`rooms/${roomId}`);
    },
    updateClinicRoom(id, data) {
      return base.put(`rooms/${id}`, data);
    },
    getRoomsByName(clinicId, name) {
      return base.get(`rooms/${clinicId}`, {
        params: { name }
      });
    },
    addClinicProcedureToRoom(props) {
      return base.post(`clinicProcedureRoom`, props);
    },
    deleteProcedureFromRoom(id) {
      return base.delete(`clinicProcedureRoom/${id}`);
    },
    getBillsBalanceWeek(clinicId, startDay, endDay, bankAccountId) {
      const config = {
        params: { startDay, endDay, bankAccountId }
      }
      return base.get(`financial/dashboard/${clinicId}/billsBalanceWeek`, config)
    },
    getBillsBalance(clinicId, startDay, endDay, bankAccountId) {
      const config = {
        params: { startDay, endDay, bankAccountId }
      }
      return base.get(`financial/dashboard/${clinicId}/billsBalance`, config)
    },
    getAllClinics(simple) {
      const config = {
        params: { simple }
      }
      return base.get('clinics', config);
    },
    getClinicAddress(clinicId) {
      return base.get(`clinic/${clinicId}/address`);
    },
    getAttendanceDocs (attendanceId) {
      return base.get(`getAttendanceDocs/${attendanceId}`);
    },
    getAttendanceFile(id) {
      return base.get(`attendance-files/${id}`)
    },
    createAttendanceFile(clinicId, personId, distinctType, data) {
      const config = {
        params: { clinicId, personId, distinctType }
      }

      return baseUpload.post(`attendance-files`, data, config)
    },
    updateAttendanceFile(fileId, data) {

      return baseUpload.post(`attendance-files/${fileId}`, data)
    },
    deleteAttendanceFile(fileId) {

      return base.delete(`attendance-files/${fileId}`)
    },
    contact(contactData) {
        return base.post('contact', contactData)
    },
    searchCrm(crmData) {
        return base.get('search-crm', { params: crmData })
    },
    searchProcedure(procedureData) {
        return base.get('procedures/search', { params: procedureData })
    },
    getAntecedentes() {
        return base.get('antecedentes-oftalmologicos')
    },
    getAlergias() {
        return base.get('alergias')
    },
    getEncaminhamento() {
        return base.get('encaminhamento')
    },
    getExamesOftalmologicos() {
        return base.get('exames-oftalmologicos')
    },
    getAntecedentesPessoais() {
        return base.get('antecedentes-pessoais')
    },
    getCirurgiasOftalmologicas() {
        return base.get('cirurgias-oftalmologicas')
    },
    getNationalities(parameters) {
        return base.get('nacionalidades', {params: parameters})
    },
    getAncestries() {
      return base.get('ancestries')
    },
    getEthnicities() {
        return base.get('ethnicities')
    },    
    getApacTypes() {
      return base.get('apac-types')
    },
    getStates() {
      return base.get('states')
    },
    getExitStayReasons() {
      return base.get('apac-get-exit-stay-reasons')
    },
    getCharacterService() {
      return base.get('apac-character-services')
    },
    getTypeService() {
      return base.get('apac-type-services')
    },
    searchCid10(cid10Data) {
        return base.get('cid10', { params: cid10Data })
    },
    getCidById(id) {
      return base.get(`cid10/${id}`)
    },
    getAnestesias() {
      return base.get('anestesias')
    },
    getPostOperativeGuidelines(data) {
      return base.get('postOperativeGuideline', { params: data })
    },
    createPostOperativeGuidelines(data) {
      return base.post('postOperativeGuideline', data);
    },

    deletePostOperativeGuidelines(id) {
      return base.delete(`postOperativeGuideline/${id}`);
    },

    updatePostOperativeGuidelines(id, data) {
      return base.put(`postOperativeGuideline/${id}`, data)
    },

    // Laudos médicos

    getMedicalReports(clinicId, type) {
      const config = {
        params: { clinicId, type }
      }
      return base.get('medicalReports', config)
    },
    getDefaultMedicalReports() {
      return base.get('getDefaultMedicalReports',)
    },
    storeMedicalReport(data) {
      return base.post('medicalReports', data)
    },
    updateMedicalReport(id, data) {
      return base.put(`medicalReports/${id}`, data)
    },
    deleteMedicalReport(id) {
      return base.delete(`medicalReports/${id}`)
    },
    allLaudableProcedures(clinicId) {
      return base.get(`allLaudableProcedures/${clinicId}`);
    },

    getComplementGlaucomaOptions(){
      return base.get(`attendance/glaucoma-complement/options`);
    },

    updateLaudableProcedure(itemId, active, limit_time, clinicId) {
      const config = {
         itemId, active, limit_time, clinicId
      }
      return base.post(`updateLaudableProcedure`, config)
    },

    allMedicalReportsResponsibles(clinicId) {
      return base.get(`allMedicalReportsResponsibles/${clinicId}`);
    },

    updateMedicalReportsResponsible(professionalId, active, clinicId) {
      const config = {
        professionalId, active, clinicId
      }
      return base.post(`updateMedicalReportsResponsible`, config)
    },

    allLaudableProceduresResponsibles(clinicId) {
      return base.get(`allLaudableProceduresResponsibles/${clinicId}`);
    },

    updateLaudableProcedureResponsible(responsibleId, laudableProcedureId, active) {
      const config = {
        responsibleId, laudableProcedureId, active
      }
      return base.post(`updateLaudableProcedureResponsible`, config)
    },

    getLaudableProcedureResponsibles(procedureId) {
      return base.get(`getLaudableProcedureResponsibles/${procedureId}`);
    },

    createOrUpdateMedicalReportFile(fileId, itemId, status = 'PENDING', responsiblesIds, attendanceId = null, medicalReportId = null) {
      const config = {
        medicalReportId, fileId, itemId, status, responsiblesIds, attendanceId
      }
      return base.post(`createOrUpdateMedicalReportFile`, config)
    },
    getMedicalReportFile(id){
      return base.get(`getMedicalReportFile/${id}`)
    },
    getMedicalReportsFiles(data, page = 1){
      const request = {
        params: { data }
      }
      return base.get(`getMedicalReportsFiles?page=${page}`, request)
    },
    getMedicalReportsFilesStatusResume(personId) {
      return base.get(`medicalReports/status/resume/${personId}`)
    },
    getPendingMedicalReportsResume(personId, clinicId) {
      const config = {
        params: { clinic_id: clinicId }
      }
      return base.get(`medicalReports/pending/resume/${personId}`, config)
    },

    createOrUpdateMedicalReportText(medicalRecordId = null, attendanceId, value) {
      const config = {
        medicalRecordId, attendanceId, value
      }
      return base.post(`createOrUpdateMedicalReportText`, config)
    },

    deleteMedicalReportFile(id) {
      return base.delete(`deleteMedicalReportFile/${id}`)
    },

    // Solicitação de tratamento
    createOtherInformation(data) {
      return base.post(`otherInformation`, data)
    },
    updateOtherInformation(id, data) {
      return base.put(`otherInformation/${id}`, data)
    },
    deleteOtherInformation(id) {
      return base.delete(`otherInformation/${id}`)
    },
    getOtherInformations(professionalId) {
      return base.get(`otherInformation`, {
        params: { professional_id: professionalId }
      })
    },
    getPatientTreatments(patientId) {
      return base.get(`patientTreatment`, {
        params: { patient_id: patientId }
      })
    },
    updatePatientTreatmentDose(id, data) {
      return base.put(`patientTreatmentDose/${id}`, data)
    },

    searchMedicines(medicineData) {
        return base.get('medicines', { params: medicineData })
    },
    searchHealthPlan(clinicId, query) {
        return base.get(`healthPlans/${clinicId}/search`, { params: { query } })
    },
    createHealthPlan(data) {
      return base.post(`healthPlans`, data)
    },
    updateHealthPlan(id, data) {
      return base.put(`healthPlans/${id}`, data)
    },
    searchPlan(healthPlanId, query) {
      return base.get(`plans/${healthPlanId}/search`, {
          params: { query }
      });
    },
    createPlan(data) {
      return base.post(`plans`, data)
    },
    addAccount(ClinicId, accountData){
        return base.post('clinics/' + ClinicId + '/create-account')
    },
    getAccounts(ClinicId){
        return base.get('clinics/' + ClinicId + '/get-accounts')
    },
    addType(typeData){
        return base.post('create-type')
    },
    getTypes(){
        return base.get('get-types')
    },
    getPlan(healthPlanId) {
        return base.get('health-plan/' + healthPlanId + '/plans')
    },
    getStandards(personId, type = null){
      const config = {
        params: { personId, type }
      }
      return base.get(`standardRecords`, config)
    },
    getStandardsRecordByPerson(personId){
      return base.get(`standardRecordByPersonId/${personId}`)
    },
    getStandardById(id){
      return base.get(`standardRecordById/${id}`)
    },
    updateStandard(id, props){
      return base.put(`standardRecord/${id}`, props)
    },
    acceptTerms(){
      return base.post('acceptTerms')
    },
    acceptTermsPerson(patientId){
      return base.post(`acceptTermsPerson/${patientId}`)
    },
    checkTerms(){
      return base.get('checkTerms')
    },
    checkTermsPerson(patientId){
      return base.get(`checkTermsPerson/${patientId}`)
    },
    userLogin(email) {
      const config = {
        params: { email }
      }
      return base.get(`userLogin`, config)
    },
    login(loginData) {
      return base.post('login', loginData)
    },
    checkUserPassword(password) {
      const config = {
        params: { password }
      }
      return base.get(`checkUserPassword`, config)
    },
    logout() {
      return base.post('logout')
    },
    isLogged() {
        if (getAccessToken()) {
          const clinic = getCurrentClinic()
          const config = {
            params: { clinicId: clinic.id  }
          }
          return base
              .get('check-user', config)
              .then(res => {
                  const resData = res.data
                  setCurrentUser(resData.user)
                  setCurrentClinic(resData.user.clinics.find(el => el.id === clinic.id))
                  setPermissions(resData.user.permissions)

                  try {
                    const userNameParts = resData.user.name.split(' ')
                    beamer_config.user_firstname = userNameParts[0]
                    beamer_config.user_lastname = (userNameParts[userNameParts.length -1] ?? '') + ` - ${clinic?.name || 'unidefined clinic'}`
                    beamer_config.user_email = resData.user.email
                    beamer_config.user_id = resData.user.id
                    beamer_config.user_created_at = resData.user.created_at
                  } catch(err) {
                    console.log('Failed set beamer user ', err)
                  }

                  return true
              })
              .catch(() => {
                clearLocalStorage();
                return false
              })
        } else {
            return false
        }
    },
    register(registerData) {
      return base.post('register', registerData)
    },
    checkKey(key){
        return base.get('validate-key', { params: {'key': key}})
    },
    getKeys(){
        return base.get('list-keys')
    },
    generateKey(){
        return base.post('generate-key')
    },
    confirm(confirmData) {
        return base.post('confirm', confirmData)
    },
    forgotPassword(forgotPasswordData) {
        return base.post('forgot-password', forgotPasswordData)
    },
    checkForgotPasswordToken(forgotPasswordData) {
        return base.get('forgot-password', { params: forgotPasswordData })
    },
    resetPassword(resetPasswordData) {
        return base.post('reset-password', resetPasswordData)
    },
    checkInvitationCode(invitationCodeData) {
        return base.get('check-invitation', { params: invitationCodeData })
    },
    userExists(email) {
      const config = {
        params: { email }
      }
      return base.get(`user-exists`,  config)
    },
    acceptInvite(inviteData) {
        return base.post('accept-invite', inviteData)
    },
    removeProcedure(clinicId, appointmentId, procedureId) {
        return base.delete(
            `clinics/${clinicId}/appointments/${appointmentId}/procedure/${procedureId}`
        )
    },
    getPermissions() {
        const { permissions } = JSON.parse(localStorage.getItem('clinic'))

        return permissions
    },
    hasPermission(permission) {
        const { permissions } = JSON.parse(localStorage.getItem('clinic'))

        if (permissions.includes('*')) {
            return true
        }

        return permissions.includes(permission)
    },
    getBusyDateTimes(data) {
      const config = {
        params: data
      }
      return base.get(`schedule/getBusyDateTimes`, config)
    },
    checkPeriods(clinicId) {
      return base.post(`clinics/${clinicId}/schedule/config/checkPeriods`)
    },
    savePeriods(clinicId, data) {
        return base.post(`clinics/${clinicId}/schedule/config/savePeriods`, data)
    },
    getClinicDays(clinicId) {
        return base.get(`clinics/${clinicId}/schedule/config/clinic-days`)
    },
    getDoctors(clinicId) {
        return base.get('clinics/' + clinicId + '/doctors')
    },
    getClinicDoctors(clinicId, includedDoctorInvited = false) {
        return base.get(`clinics/${clinicId}/clinicDoctors?includedDoctorInvited=${includedDoctorInvited}`);
    },
    getAllClinicDoctors(clinicId) {
        return base.get('clinics/' + clinicId + '/allClinicDoctors')
    },
    getScheduleConfig(clinicId, roomId, professionalId) {
      const config = {
        params: { clinicId, roomId, professionalId }
      }
      return base.get(`schedule/scheduleConfigDays`, config)
    },
    getScheduleSettings(clinicId, roomIds, professionalIds, start = null, end = null) {
      const config = {
        params: { clinicId, roomIds, professionalIds, start, end }
      }
      return base.get(`clinics/${clinicId}/schedule/config`, config)
    },
    getDoctorScheduleConfig(clinicId, doctorId) {
        return base.get(`clinics/${clinicId}/schedule/config/${doctorId}/days`)
    },
    getRoomScheduleConfig(clinicId, roomId) {
      return base.get(`schedule/${clinicId}/${roomId}/scheduleConfigDays`)
    },
    getClinicScheduleConfig(clinicId) {
      return base.get(`clinics/${clinicId}/schedule/config/days`)
    },
    saveScheduleClinicConfigDay(clinicId, data) {
        return base.post(
            `clinics/${clinicId}/schedule/config/days`,
            data
        )
    },
    saveScheduleConfigDay(clinicId, doctorId, data) {
        return base.post(
            `clinics/${clinicId}/schedule/config/${doctorId}/days`,
            data
        )
    },
    updateScheduleConfigDay(clinicId, configId, data) {
        return base.put(
            `clinics/${clinicId}/schedule/config/days/${configId}`,
            data
        )
    },
    removeScheduleConfigDay(clinicId, configId) {
        return base.delete(`clinics/${clinicId}/schedule/config/days/${configId}`)
    },
    createScheduleConfigDayInterval(data){
      return base.post(`schedule/intervals`, data);
    },
    deleteScheduleConfigDayInterval(id){
      return base.delete(`schedule/intervals/${id}`);
    },
    updateScheduleConfigDayInterval(id, props) {
      return base.put(`schedule/intervals/${id}`, props);
    },
    updateDurationTime(clinicId, scheduleConfigId, duration) {
        return base.put(`clinics/${clinicId}/schedule/config/${scheduleConfigId}`, {
            duration_time: duration
        })
    },
    getAttendanceType(clinicId) {
      return base.get(`clinics/${clinicId}/schedule/config/attendance-type`)
    },
    saveAttendanceType(clinicId, data) {
        return base.post(
            `clinics/${clinicId}/schedule/config/attendance-type`,
            data
        )
    },
    saveStatus(clinicId, data){
        return base.post(
            `clinics/${clinicId}/schedule/config/day-status`,
            data
        )
    },
    getExceptionDate(clinicId) {
        return base.get(`clinics/${clinicId}/schedule/config/clinicExceptions`)
    },
    getDoctorExceptionDate(clinicId, doctorId) {
      return base.get(`clinics/${clinicId}/schedule/config/doctorExceptions?professional_id=${doctorId}`)
    },
    destroyDoctorException(clinicId, id) {
      return base.post(`clinics/${clinicId}/schedule/config/doctor-exceptions/${id}`)
    },
    addExceptionDate(clinicId, data) {
      return base.post(`clinics/${clinicId}/schedule/config/exceptions`, data)
    },
    destroyExceptionDate(clinicId, id) {
      return base.delete(`clinics/${clinicId}/schedule/config/exceptions/${id}`)
    },
    destroyClinicException(clinicId, id) {
      return base.post(`clinics/${clinicId}/schedule/config/clinic-exceptions/${id}`)
    },
    getOutagesWithType(clinicId, id, type) {
      return base.get(`clinics/${clinicId}/schedule/config/outages?id=${id}&type=${type}`)
    },
    getOutages(clinicId, data) {
      return base.get(`clinics/${clinicId}/schedule/config/outages?id=${data}`)
    },
    getAllOutages(clinicId) {
      return base.get(`clinics/${clinicId}/schedule/config/all-outages`)
    },
    addOutages(clinicId, data) {
        return base.post(`clinics/${clinicId}/schedule/config/outages`, data)
    },
    updateOutages(clinicId, data) {
        return base.post(`clinics/${clinicId}/schedule/config/update-outages`, data)
    },
    removeOutages(clinicId, data) {
        return base.post(`clinics/${clinicId}/schedule/config/remove-outages`, data)
    },
    getOutagesReasons(clinicId, type) {
        return base.get(`clinics/${clinicId}/schedule/config/outages-reasons?type=${type}`)
    },
    addOutagesReasons(clinicId, data) {
        return base.post(`clinics/${clinicId}/schedule/config/outages-reasons`, data)
    },
    updateOutagesReasons(clinicId, data) {
        return base.post(`clinics/${clinicId}/schedule/config/update-outages-reasons`, data)
    },
    removeOutagesReasons(clinicId, data) {
        return base.post(`clinics/${clinicId}/schedule/config/remove-outages-reasons`, data)
    },
    myData() {
      return base.get('my-data')
    },
    updateMyData(myDataData) {
      return base.post('my-data', myDataData)
    },
    updateMyPicture(data) {
      return baseUpload.post('update-my-picture', data)
    },
    getOverview(clinicId) {
        return base.get('clinics/' + clinicId + '/overview')
    },
    /*  Deprecated route
    getFinishedAppointmentOfLast24Hours(clinicId, doctorId) {
      const config = {
        params: {
          clinicId,
          doctorId
        }
      };
      return base.get('appointments/finishedAppointments', config);
    },  */
    getAppointmentsByPatientToday(patientId) {
      return base.get(`appointments/${patientId}/today`);
    },
    getByFilters(patient_id, professional_id, start, end, status, item, currentPage) {
      const config = {
        params: {
          patient_id,
          professional_id,
          start,
          end,
          status,
          item,
          currentPage,
        }
      };
      return base.get(`appointments/getByFilters?page=${currentPage}`, config);
    },
    getResumeValues(patientId) {
      return base.get(`appointmentsGetResumeValues/${patientId}`);
    },
    loadForm(clinicId) {
        return base.get('clinics/' + clinicId + '/load-form')
    },
    showClinic(clinicId) {
        return base.get('clinics/' + clinicId)
    },
    updateClinic(clinicId, clinicData) {
        return base.post('clinics/' + clinicId, clinicData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    updateClinicData(clinicId, data) {
      return base.put('clinics/' + clinicId, data)
    },
    showClinicProcedures(clinicId) {
        return base.get('clinics/' + clinicId + '/procedures')
    },
    storeClinicProcedures(clinicId, procedureData) {
        return base.post('clinics/' + clinicId + '/procedures', procedureData)
    },
    updateClinicProcedures(clinicId, procedureId, procedureData) {
        return base.put(
            'clinics/' + clinicId + '/procedures/' + procedureId,
            procedureData
        )
    },
    destroyClinicProcedures(clinicId, procedureId) {
        return base.delete('clinics/' + clinicId + '/procedures/' + procedureId)
    },
    showClinicHealthPlans(clinicId, healthPlanData) {
        return base.get('clinics/' + clinicId + '/health-plans', {
            params: healthPlanData
        })
    },
    storeClinicHealthPlans(clinicId, healthPlanData) {
        return base.post('clinics/' + clinicId + '/health-plans', healthPlanData)
    },
    updateClinicHealthPlans(clinicId, healthPlanId, healthPlanData) {
        return base.put(
            'clinics/' + clinicId + '/health-plans/' + healthPlanId,
            healthPlanData
        )
    },
    destroyClinicHealthPlans(clinicHealthPlanId) {
        return base.delete(`clinicHealthPlan/${clinicHealthPlanId}`)
    },
    findClinicHealthPlan(id) {
      return base.get(`clinicHealthPlan/${id}`)
    },
    findClinicHealthPlanToUpdate(id, clinicId) {
      return base.get(`clinicHealthPlan/findToUpdate/${id}`, {
        params: { clinicId }
      })
    },

    getActiveInvoicingTissClinicHealthPlans(clinicId, page, query = null, paginate = null, withTrashed) {
      return base.get(`getActiveInvoicingTissClinicHealthPlans/${clinicId}?page=${page}`, {
        params: { query, paginate, withTrashed }
      })
    },

    getInvoicingTissSetting(id){
      return base.get('getInvoicingTissSetting/'+ id)
    },
    getMaxGuidesBatch(id){
      return base.get('invoicingTissSetting/'+ id + '/getMaxGuidesBatch')
    },
    getInvoicingTissSettingProfessionals(invoicingTissSettingId) {
      return base.get('invoicingTissSettingsProfessional', {
        params: { invoicing_tiss_setting_id: invoicingTissSettingId }
      })
    },
    findTissTableProcedureById(id) {
      return base.get(`tissTableProcedure/${id}`)
    },
    updateTissTableProcedures(id, data) {
      return base.put(`tissTableProcedure/${id}`, data)
    },
    storeManyTissTableProcedures(tissTableImportId, invoicingTissSettingId, clinicId, clinicHealthPlanId, values) {
      return base.get('storeManyTissTableProcedures', {
        params: {
          tiss_table_import_id: tissTableImportId,
          clinic_id: clinicId,
          invoicing_tiss_setting_id: invoicingTissSettingId,
          clinic_health_plan_id: clinicHealthPlanId,
          values
        }
      })
    },
    updateManyTableProcedures(tissTableImportId, invoicingTissSettingId, clinicId, clinicHealthPlanId ,values) {
      return base.get('updateManyTissTableProcedures', {
        params: {
          tiss_table_import_id: tissTableImportId,
          clinic_id: clinicId,
          invoicing_tiss_setting_id: invoicingTissSettingId,
          clinic_health_plan_id: clinicHealthPlanId,
          values
        }
      })
    },
    findTissTableProcedure(procedureId, invoicingTissSettingId) {
      return base.get(`findTissTableProcedure/${procedureId}/${invoicingTissSettingId}`)
    },

    showClinicSecretaries(clinicId) {
        return base.get('clinics/' + clinicId + '/secretaries')
    },
    storeClinicSecretaries(clinicId, secretaryData) {
        return base.post('clinics/' + clinicId + '/secretaries', secretaryData)
    },
    updateClinicSecretaries(clinicId, secretaryId, secretaryData) {
        return base.put(
            'clinics/' + clinicId + '/secretaries/' + secretaryId,
            secretaryData
        )
    },
    destroyClinicSecretaries(clinicId, secretaryId) {
        return base.delete('clinics/' + clinicId + '/secretaries/' + secretaryId)
    },
    showClinicProfessionals(clinicId, query, role) {
      const config = {
        params: { query, role }
      }
      return base.get('clinics/' + clinicId + '/professionals', config)
    },
    storeClinicProfessionals(clinicId, professionalData) {
        return base.post('clinics/' + clinicId + '/professionals', professionalData)
    },
    updateClinicProfessionals(clinicId, professionalId, professionalData) {
        return base.put(
            'clinics/' + clinicId + '/professionals/' + professionalId,
            professionalData
        )
    },
    destroyClinicProfessionals(clinicId, professionalId) {
        return base.delete(
            'clinics/' + clinicId + '/professionals/' + professionalId
        )
    },
    showClinicPatients(clinicId, patientData) {
        return base.get('clinics/' + clinicId + '/patients', {
            params: patientData
        })
    },
    validateCpf(clinicId, cpf) {
        return base.post(
            'clinics/' + clinicId + '/patients/validateCpf?cpf=' + cpf)
    },
    getClinicSettings(clinicId) {
      return base.get('clinic/settings', {
        params: { clinicId }
      })
    },
    updateClinicSettings(id, data) {
      return base.put(`clinic/settings/${id}`, data);
    },
    findPatientBy(clinicId, by, value) {
      return base.get('people/findPatientBy', {
        params: { clinicId, by, value }
      })
    },
    quickRegistrationClinicPatients(clinicId, patientData) {
        return base.post(
            'clinics/' + clinicId + '/patients/quick-registration',
            patientData
        )
    },
    getCompletePatient(personId, withTrashed = false) {
      const config = {
        withTrashed: withTrashed
      }
      return base.get(`people/${personId}/patient`,config)
    },
    createPerson(data) {
      return baseUpload.post(`people`, data)
    },
    deletePerson(personId) {
      return base.delete(`people/${personId}`)
    },
    updatePerson(personId, data) {
      return baseUpload.post(`people/${personId}`, data)
    },
    deletePersonPlan(personId) {
      return base.delete(`personPlans/${personId}`)
    },
    createHealthPersonPlan(data) {
      return base.post(`personHealthPlans`, data)
    },
    deleteHealthPersonPlan(personHealthPlanId) {
      return base.delete(`personHealthPlans/${personHealthPlanId}`)
    },
    showPatientDashboard(clinicId, patientId) {
        return base.get(
            'clinics/' + clinicId + '/patients/' + patientId + '/dashboard'
        )
    },
    getPatientEyeDrops(patientId) {
        return base.get(
            '/patients/' + patientId + '/getPatientEyedrops'
        )
    },
    getAllPatientEyeDrops(patientId) {
      return base.get(
          '/patients/' + patientId + '/getAllPatientEyedrops'
      )
    },
    getPatientMedicalHistory(clinicId, patientId) {
        return baseProd.get(
            'clinics/' + clinicId + '/patients/' + patientId + '/medical-history'
        )
    },
    getPatientMedicalHistoryDashboard(clinicId, patientId) {
        return baseProd.get(
            'clinics/' + clinicId + '/patients/' + patientId + '/medical-history-dashboard'
        )
    },
    getPatientSummary(patientId, types) {
      const config = {
        params: { patientId, types }
      }
      return base.get('patientSummaries', config);
    },
    storePatientSummary(data) {
      return base.post('patientSummaries', data)
    },
    deleteSummaryItem(id) {
      return base.delete(`patientSummaries/${id}`)
    },
    updateClinicPatients(clinicId, patientId, patientData) {
        return base.post(
            'clinics/' + clinicId + '/patients/' + patientId,
            patientData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
    },
    createProductionSheet(clinicId, sheet, year, month) {
        return base3.post(
            'clinics/' + clinicId + '/create-sheet',{
                sheet: sheet,
                year: year,
                month: month
            },
        )
    },
    getAttendance(clinicId, patientId, attendanceId, attendanceData) {
        return base.get(
            'clinics/' +
            clinicId +
            '/patients/' +
            patientId +
            '/attendance/' +
            attendanceId, { params: attendanceData }
        )
    },
    getProfessionalCrm(clinicId, patientId, attendanceId, person) {
        return base.get(
            'clinics/' +
            clinicId +
            '/patients/' +
            patientId +
            '/attendance/' +
            attendanceId +
            '/crm?person_id=' +
            person
        )
    },
    startAttendance(clinicId, patientId, appointmentId, formId, type = 'ATTENDANCE') {
      return base.post(
        'clinics/' + clinicId + '/patients/' + patientId + '/start-attendance', {
            appointment: appointmentId,
            form_id: formId,
            type: type
        }
      )
    },
    printAppointmentResume(appointmentId) {
      return baseFile.get(`document/${appointmentId}/manual-attendance`)
    },
    printAppointmentDocument(appointmentId, style) {
      return baseFile.get(`document/appointment-doc/${appointmentId}/${style}`)
    },
    printStatementOfAwareness(patientId, procedures) {
      return baseFile.get(`document/${patientId}/statement-of-awareness`, {
        params: { procedures }
      })
    },
    startAnnotation(clinicId, patientId) {
      return base.post(
          'clinics/' + clinicId + '/patients/' + patientId + '/start-annotation')
    },
    getAnnotations(page, clinicId, patientId) {
      const config = {
        params: { page, clinicId, patientId }
      }
      return base.get('annotations', config);
    },

    getAnnotation(id){
      const config = {
        params: { id }
      }
      return base.get('annotations/get', config);
    },

    getAllAnnotations(clinicId, patientId) {
      const config = {
        params: { clinicId, patientId }
      }
      return base.get('annotations/allAnnotations', config);
    },
    saveAnnotation(clinicId, patientId, data) {
        return base.post(
            'clinics/' + clinicId + '/patients/' + patientId + '/save-annotation', data)
    },
    cancelAnnotation(clinicId, patientId, data) {
        return base.post(
            'clinics/' + clinicId + '/patients/' + patientId + '/cancel-annotation', data)
    },
    getAttendanceById(attendanceId) {
      return base.get(`attendance/${attendanceId}`);
    },
    updateAttendance(clinicId, patientId, attendanceData) {
        return base.put(
            'clinics/' + clinicId + '/patients/' + patientId + '/attendance',
            attendanceData
        )
    },
    updateAttendanceApi(id, attendanceData) {
      return base.put(`/attendance/${id}`, attendanceData)
    },
    openAttendance(attendanceId) {
      return base.post(`/openAttendance/${attendanceId}`)
    },
    pauseAttendance(clinicId, attendanceData, pauseReason) {

        return base.post(`clinics/${clinicId}/attendance/${attendanceData}/pauseAttendance?reason=${pauseReason}`)
    },
    unpauseAttendance(clinicId, attendanceData) {
        return base.post(
            'clinics/' + clinicId + '/attendance/' + attendanceData + '/unpauseAttendance',
        )
    },
    simpleFinishAttendance(attendanceId) {
      return base.post(`attendances/${attendanceId}/simpleFinish`)
    },
    simpleFinishPreAttendance(attendanceId) {
      return base.post(`attendances/${attendanceId}/simpleFinishPreAttendance`)
    },
    createAttendanceExam() {
      return base.post(`attendances/createAttendanceExam`)
    },
    autoFinishAttendance(clinicId, attendanceId) {
        return base.post(
            'clinics/' + clinicId + '/attendance/' + attendanceId + '/autoFinish',
        )
    },
    /**
     *
     * @param {Object} data<clinic, attendance>
     * @returns {Promise<AxiosResponse<any>>}
     */
    resetAttendanceAndAttendanceItems(data) {
        return base.put(`attendance/reset`, data)
    },

    // deprecated
    uploadFilesAttendance(clinicId, patientId, data) {
        return baseUpload.post(
            'attendance/clinics/' + clinicId + '/patients/' + patientId + '/saveFiles', data)
    },
    // deprecated
    removeFilesAttendance(clinicId, patientId, data) {
        return base.post(
            'clinics/' + clinicId + '/patients/' + patientId + '/removeFiles', data)
    },
    updateAttendanceForm(id, data) {
      return base.put('attendance-form/'+ id, data)
    },
    uploadDocs(clinicId, patientId, distinctType, data) {
      const config = {
        params: { distinctType }
      }
      return baseUpload.post(`document/${clinicId}/${patientId}/saveDocs`, data, config)
    },
    deleteDoc(attendanceFileId) {
      return baseUpload.delete(`document/${attendanceFileId}/delete`)
    },
    uploadClinicFile(clinicId, data, listeners) {
      return baseUpload.post(`document/${clinicId}/uploadFile`, data, listeners)
    },
    uploadCsvFile(clinicId, params, data) {
      const config = { params }
      return baseUpload.post(`csvimport/${clinicId}`, data, config)
    },
    getImportById(importId) {
      return base.get(`csvimport/${importId}`)
    },
    getImportsByClinicId(clinicId) {
      return base.get(`csvimport/imports/${clinicId}`);
    },
    getImportsByClinicIdAndTypes(clinicId, types) {
      return base.get(`csvimport/imports/${clinicId}`, { params: { types } });
    },
    getImportsByClinicIdAndTiss(clinicId) {
      return base.get(`csvimport/tissImports/${clinicId}`);
    },
    saveClinicBrand(clinicId, data) {
      return baseUpload.post(`clinic/${clinicId}/saveBrand`, data)
    },
    createOrUpdateHealthPlanBrand(clinicHealthPlanId, data) {
      return baseUpload.post(`clinicHealthPlan/${clinicHealthPlanId}/brand`, data)
    },
    uploadTissTable(data) {
      return baseUpload.post('tissTableImport', data)
    },
    uploadCustomTissTable(data) {
      return baseUpload.post('importCustomTissTable', data)
    },
    getTissTables(clinic_id) {
      return base.get('tissTableImport', { params: { clinic_id } })
    },
    findTissTable(id) {
      return base.get(`tissTableImport/${id}`)
    },
    deleteTissTable(id) {
      return base.delete(`tissTableImport/${id}`)
    },
    // deprecated
    sendFile(attendance, json) {
        return base.post('attendance-file/' + attendance, json)
    },
    // deprecated
    getFile(attendance) {
        return base.get('attendance-file/' + attendance)
    },
    getAllDocs(patient) {
      return base.get(`attendance/all-documents/${patient}/doc`)
    },
    getAllExams(patient) {
      return base.get(`attendance/all-documents/${patient}/exam`)
    },
    getAttendanceFiles(attendanceId, loadTemporaryUrl) {
      const config = {
        params: { loadTemporaryUrl }
      }
      return base.get(`attendance/${attendanceId}/files`, config)
    },
    getDocTemporaryUrl(path) {
      const config = {
        params: { path }
      }
      return base.get('document/temporaryUrl', config)
    },
    getDocumentFile(attendanceFileId) {
      return baseFile.get(`document/${attendanceFileId}/file`);
    },
    getPrintableDocumentFile(attendanceFileId) {
      return baseFile.get(`document/${attendanceFileId}/printable-document`);
    },
    getPrintableDocumentFileUrl(attendanceFileId) {
      return base.get(`document/${attendanceFileId}/printable-document-url`);
    },
    finishAttendance(data) {
      return base.post(`document/finishAttendance`, { ...data, tabId: window.sessionStorage.getItem('tabId') })
    },
    oauthFinishAttendance(data) {
      return base.post(`document/oauthFinishAttendance`, data)
    },
    restFinishAttendance(attendanceId) {
      return base.post(`document/${attendanceId}/restFinishAttendance`)
    },


    sendSignatureCloud(attendance, data, toSign, toPrint, types, service, cpf) {
      const config = {
        params: { attendance, toSign, toPrint, types, service, cpf }
      }
      return base.post('document/create', data, config);
    },
    sendSignature(attendance, signature, certContent, data, toSign, toPrint, types) {
      const config = {
        params: { attendance, signature, certContent, toSign, toPrint, types }
      };
      return base.post('document/create', data, config);
    },
    sendSignatureBatch(attendance, certContent, data, types) {
      const config = {
        params: { attendance, certContent, types }
      };
      return base.post('document/createBatch', data, config);
    },
    discoverCerts(clinicId) {
      return base.get(`lacuna/${clinicId}/discovery`)
    },
    webCertCheckAuth(data) {
      return base.post('lacuna/webCertCheckAuth', data)
    },
    sendSignatureComplete(token, type, fileId) {
      const config = {
        params: { token, type, fileId }
      }
      return base.post('lacuna/complete', {}, config)
    },
    sendSignatureCloudComplete(code, state) {
      const config = {
        params: { code, state }
      }
      return base.post('lacuna/complete', {}, config)
    },
    sendPrintFile(clinicId, patientId, attendance, data, type) {
        return base2.post(
            'pades-signature-rest/print.php?type=' +
            type +
            '&attendance=' +
            attendance,
            data
        )
    },
    createDocumentAndPrint(data) {
      return base.post('document/create/print', data);
    },
    searchAppointments(clinicId, page, props) {
      const data = { page, props }
      return base.post(`clinics/${clinicId}/searchAppointments`, data);
    },
    changeManyAppointmentSituation(data) {
      return base.post(`appointments/updateManyStatus`, data);
    },
    getAppointmentsOld(clinicId, appointmentsData) {
        return base.get('clinics/' + clinicId + '/appointments', {
            params: appointmentsData
        })
    },
    getAppointments(clinicId, params) {
      return base.get(`appointments/${clinicId}/getScheduleAppointments`, { params: { ...params, clinicId } })
    },
    getAppointmentsResume(clinicId, page=1, filters={}) {
      const request = {
        params: { filters }
      }
      return base.get(`appointmentsResume/${clinicId}?page=${page}`, request)
    },
    getAppointmentDetails(appointmentId) {
      return base.get(`/appointments/${appointmentId}/details`)
    },  
    getAppointment(clinicId, appointmentId) {
        return base.get(`/clinics/${clinicId}/appointments/${appointmentId}`)
    },
    getDayAppointments(params) {
      const config = {
        params
      }
        return base.get('appointments/day', config)
    },
    // deprecated
    storeAppointment(clinicId, appointmentsData) {
        return base.post('clinics/' + clinicId + '/appointments', appointmentsData)
    },
    createAppointment(data) {
      return base.post('appointments', data)
    },
    updateAppointment(appointmentId, data) {
      return base.put(`appointments/${appointmentId}`, data);
    },
    // old method
    oldUpdateAppointment(clinicId, appointmentId, appointmentsData) {
        return base.put(
            'clinics/' + clinicId + '/appointments/' + appointmentId,
            appointmentsData
        )
    },
    destroyAppointment(appointmentId) {
        return base.delete(`appointments/${appointmentId}`)
    },
    changeStatus(clinicId, appointmentId, appointmentsData) {
      return base.post(
          'clinics/' + clinicId + '/appointments/' + appointmentId + '/changeStatus',
          appointmentsData
      )
    },
    storePayment(clinicId, paymentData) {
        return base.post('clinics/' + clinicId + '/save-payment', paymentData)
    },
    getPayment(clinicId, appointmentId) {
        return base.get('clinics/' + clinicId + '/get-payment/' + appointmentId)
    },
    storePreConsultation(clinicId, data) {
        return base.post(`clinics/${clinicId}/pre-consultations`, data)
    },
    updatePreConsultation(clinicId, id, data) {
        return base.put(`clinics/${clinicId}/pre-consultations/${id}`, data)
    },
    getAppointmentProcedure(appointmentId, clinicProcedureId) {
      const config = {
        params: { appointmentId, clinicProcedureId }
      }
      return base.get('appointmentProcedure', config);
    },

    // people endpoints
    getPerson(personId) {
      return base.get(`people/${personId}/person`);
    },
    getPatients(page, clinicId, query, paginated = true) {
      const config = {
        params: { page, clinicId, query, paginated }
      }
      return base.get(`people/patients`, config);
    },
    searchPatients(clinicId, query) {
      const config = {
        params: { clinicId, query }
      }
      return base.get(`people/search-patients`, config);
    },
    listPatients(page, clinicId, query, letter, birthdayStart, birthdayEnd, patientStatus) {
      const config = {
        params: { page, query, letter, birthdayStart, birthdayEnd, patientStatus }
      }
      return base.get(`patients/${clinicId}/listPatients`, config);
    },
    getProfessionals(page, clinicId, query, roles = []) {
      const config = {
        params: { page, clinicId, query, roles }
      }
      return base.get(`people/professionals`, config);
    },
    getAllProfessionalsWithTissSettings(clinicId, invoicingTissId) {
      return base.get(`getAllProfessionals/${clinicId}/${invoicingTissId}`);
    },

    getAllProfessionalsWithSusSettings(clinicId, query) {
      return base.get(`getProfessionalsSusSettings/${clinicId}`, { params: query });
    },

    getActiveSusProfessionals(clinicId) {
      return base.get(`getActiveSusProfessionals/${clinicId}`);
    },

    getHealthPlansByProfessionalId(professionalId, clinicId) {
        return base.get(`getByProfessionalId/${professionalId}/${clinicId}`);
    },

    getPersonalData(personId) {
        return base.get(`people/personal-data/${personId}`);
    },

    getDoctorData(doctorId, clinicId) {
        return base.get(`people/doctor-data/${doctorId}/${clinicId}`);
    },
     getBatchDoctorData(doctorIds, clinicId) {
         const data = {
             doctorIds: doctorIds,
             clinicId: clinicId
         };
        return base.post(`people/batch-doctor-data`, data);
    },

    updatePersonalData(formInfo, personInfo) {
        const config = {
            params: {formInfo, personInfo}
        }
        return base.put(`people/personal-data`, config);
    },


    // Warehouse endpoints
    getMeasureUnits() {
      return base.get(`warehouse/measureUnits`);
    },
    createProduct(data) {
      return base.post(`warehouse/products`, data);
    },
    updateProduct(id, data) {
      return base.put(`warehouse/products/${id}`, data);
    },
    getPendingProducts(page, clinicId, start, end) {
      const config = {
        params: { page, clinicId, start, end }
      }
      return base.get(`financial/pendingProducts`, config);
    },
    getProducts(clinicId, page, query, provider, start, end, quantity) {
      const config = {
        params: { clinicId, page, query, provider, start, end, quantity }
      }
      return base.get(`warehouse/products`, config);
    },
    searchProducts(clinicId, query) {
      const config = {
        params: { query }
      }
      return base.get(`warehouse/${clinicId}/products/search`, config);
    },
    deleteProduct(id) {
      return base.delete(`warehouse/products/${id}`);
    },

    createProvider(data) {
      return base.post(`warehouse/providers`, data);
    },
    updateProvider(id, data) {
      return base.put(`warehouse/providers/${id}`, data);
    },
    deleteProvider(id) {
      return base.delete(`warehouse/providers/${id}`);
    },
    getProviders(clinicId, page, query, state, city) {
      const config = {
        params: { clinicId, page, query, state, city }
      }
      return base.get(`warehouse/providers`, config);
    },
    createWarehouseEntry(data) {
      return base.post(`warehouse/entries`, data);
    },
    getWarehouseEntries(clinicId, filters) {
      const config = {
        params: filters
      }
      return base.get(`warehouse/entries/${clinicId}`, config)
    },
    createWarehouseEntryFile(data) {
      return baseUpload.post(`warehouse/entryFiles`, data);
    },
    updateWarehouseEntryFile(id, data) {
      return base.put(`warehouse/entryFiles/${id}`, data);
    },
    deleteWarehouseEntryFile(id) {
      return base.delete(`warehouse/entryFiles/${id}`);
    },
    updateWarehouseEntry(id, data) {
      return base.put(`warehouse/entries/${id}`, data);
    },
    deleteWarehouseEntry(id) {
      return base.delete(`warehouse/entries/${id}`);
    },
    getWarehouseEntryTransactions(clinicId, page, query, provider, start, end, batch, barcode) {
      const config = {
        params: { clinicId, page, query, provider, start, end, batch, barcode }
      }
      return base.get(`warehouse/entries/transactions`, config);
    },
    getWarehouseOutputTransactions(clinicId, page, query, type, start, end,
      batch, professional_id, patient_id, procedure_id, health_plan_id) {
      const config = {
        params: { clinicId, page, query, type, start, end,
          batch, professional_id, patient_id, procedure_id,
          health_plan_id
        }
      }
      return base.get(`warehouse/output/transactions`, config);
    },
    createWareHouseOutput(data) {
      return base.post(`warehouse/outputs`, data);
    },
    outputSurgeryProcedureItems(surgeryProcedures) {
      return base.post(`warehouse/output/outputSurgeryProcedureItems`, surgeryProcedures);
    },
    updateWarehouseOutput(id, data) {
      return base.put(`warehouse/outputs/${id}`, data);
    },
    getProductBatches (productId) {
      return base.get(`warehouse/getProductBatches/${productId}`);
    },
    getTransactionBatches(clinicId, page, query, expirationType, periodStart, periodEnd, manufacturingStart, manufacturingEnd) {
      const config = {
        params: { clinicId, page, query, expirationType, periodStart, periodEnd, manufacturingStart, manufacturingEnd }
      }
      return base.get(`warehouse/transactions/batches`, config);
    },
    getDistinctBatchNumbers(clinicId) {
      return base.get(`warehouse/transactions/${clinicId}/batchNumbers`);
    },
    getDistinctProductBatchNumbers(productId) {
      return base.get(`warehouse/transactions/${productId}/productBatchNumbers`);
    },
    getTransactionByProductAndBatch(productId, batch) {
      const config = {
        params: { batch }
      }
      return base.get(`warehouse/transactions/${productId}/batch`, config);
    },
    updateWarehouseTransaction(id, data) {
      return base.put(`warehouse/transactions/${id}`, data);
    },
    getWarehouseConfigurations(clinicId) {
      const config = {
        params: { clinicId }
      }
      return base.get(`warehouse/configurations`, config);
    },
    updateWarehouseConfigurations(id, data) {
      return base.put(`warehouse/configurations/${id}`, data);
    },
    deleteWarehouseTransaction(id) {
      return base.delete(`warehouse/transactions/${id}`);
    },
    getWarehouseDashboardProducts(clinicId, key, order, page) {
      const config = {
        params: { key, order }
      }
      return base.get(`warehouse/dashboard/${clinicId}/products?page=${page}`, config);
    },
    getExpiredProducts(clinicId) {
      return base.get(`warehouse/dashboard/${clinicId}/expiredProducts`);
    },
    getLowStockProducts(clinicId) {
      return base.get(`warehouse/dashboard/${clinicId}/lowStock`);
    },
    getCloseToExpirations(clinicId) {
      return base.get(`warehouse/dashboard/${clinicId}/closeExpirations`);
    },
    getProductCount(clinicId) {
      return base.get(`warehouse/dashboard/${clinicId}/productCount`);
    },
    getWarehouseDashboardMonthly(clinicId, type, month, year) {
      const config = {
        params: { type, month, year }
      }
      return base.get(`warehouse/dashboard/${clinicId}/monthly`, config);
    },
    getChartEntranceVsOutput(clinicId, start, end) {
      const config = {
        params: { start, end }
      }
      return base.get(`warehouse/dashboard/${clinicId}/entriesVsOutputs`, config);
    },
    getWarehouseNotifications(clinicId) {
      return base.get(`warehouse/${clinicId}/notifications`);
    },
    getProductsExport(clinicId, data) {
    return baseXlsx.post(`warehouse/products/${clinicId}/export`, data);
    },
    getWarehouseEntriesExport(clinicId, data) {
      return baseXlsx.post(`warehouse/entries/${clinicId}/export`, data);
    },
    getWarehouseOutputsExport(clinicId, data) {
      return baseXlsx.post(`warehouse/outputs/${clinicId}/export`, data);
    },
    getWarehouseBatchesExport(clinicId, data) {
      return baseXlsx.post(`warehouse/batches/${clinicId}/export`, data);
    },
    getWarehouseProvidersExport(clinicId, data) {
      return baseXlsx.post(`warehouse/providers/${clinicId}/export`, data);
    },
    getOutputProductSuggestions(clinicProcedureId, appointmentId) {
      const config = {
        params: { deepening: false, appointmentId }
      }
      return base.get(`warehouse/${clinicProcedureId}/outputProductSuggestions`, config);
    },

    // Warehouse Kit Templates

    createKitTemplate(data) {
      return base.post(`warehouse/kitTemplates`, data);
    },
    updateKitTemplate(kitTemplateId, data) {
      return base.put(`warehouse/kitTemplates/${kitTemplateId}`, data);
    },
    getKitTemplates(clinicId, filters=[], page=1) {
      const config = {
        params: { filters }
      };
      return base.get(`warehouse/kitTemplates/${clinicId}?page=${page}`, config);
    },
    exportKitTemplates(clinicId, filters=[]) {
      const config = {
        params: { filters }
      };
      return baseXlsx.get(`warehouse/kitTemplates/${clinicId}/export`, config);
    },
    deleteKitTemplate(kitTemplateId) {
      return base.delete(`warehouse/kitTemplates/${kitTemplateId}`);
    },

    // Warehouse Kits

    createKit(data) {
      return base.post(`warehouse/kits`, data);
    },
    updateKit(kitId, data) {
      return base.put(`warehouse/kits/${kitId}`, data);
    },
    getKits(clinicId, filters=[], page=1) {
      const config = {
        params: { filters }
      };
      return base.get(`warehouse/kits/${clinicId}?page=${page}`, config);
    },
    getKitsAndProducts(clinicId, barcode) {
      return base.get(`warehouse/getKitsAndProducts/${clinicId}/${barcode}`);
    },
    exportKits(clinicId, filters=[]) {
      const config = {
        params: { filters }
      };
      return baseXlsx.get(`warehouse/kits/${clinicId}/export`, config);
    },
    deleteKit(kitId) {
      return base.delete(`warehouse/kits/${kitId}`);
    },
    createKitOrigin(data) {
      return base.post(`warehouse/kitOrigin`, data);
    },
    getKitOrigins(clinicId, filters) {
      const config = {
        params: { filters }
      };
      return base.get(`warehouse/kitOrigins/${clinicId}`, config);
    },
    printBarcode (barcode) {
      return base.get(`barcode/${barcode}`);
    },

    // Procedures
    getProceduresBySearch(source, query) {
      const config = {
        params: { source, query }
      }
      return base.get(`procedure/search`, config);
    },

    // ClinicProcedures
    getAllClinicProcedures(clinicId, withDerivatives = false, search = '', paginated = false, page = 1, types = null) {
      const config = {
        params: { withDerivatives, paginated, page, search, types }
      }
      return base.get(`items/${clinicId}/all`, config);
    },
    searchClinicProcedures(clinicId, query, types) {
      const config = {
        params: { clinicId, query, types }
      }
      return base.get(`procedure/clinicProcedures/search`, config);
    },
    getClinicProcedureById(procedureId) {
      return base.get(`procedure/clinicProcedures/getById/${procedureId}`);
    },
    getClinicProcedures(clinicId, page, query, tuss, sus, type) {
      const config = {
        params: { clinicId, page, query, tuss, sus, type }
      }
      return base.get(`procedure/clinicProcedures`, config);
    },

    getClinicProceduresByClinicAndHealthPlan(clinic, healthPlan) {
      return base.get(`procedure/clinicProcedures/${clinic}/${healthPlan}`);
    },

    createClinicProcedure(data) {
      return base.post(`procedure/clinicProcedures`, data);
    },
    updateClinicProcedure(id, data) {
      return base.put(`procedure/clinicProcedures/${id}`, data);
    },
    deleteClinicProcedure(id) {
      return base.delete(`procedure/clinicProcedures/${id}`);
    },
    getProcedureHealthPlans(clinicId, clinicProcedureId, orderBy) {
      const config = {
        params: { clinicId, clinicProcedureId, orderBy }
      }
      return base.get(`procedure/procedureHealthPlans`, config);
    },
    updateProcedureHealthPlans(procedureHealthPlanId, props) {
      return base.put(`procedure/procedureHealthPlans/${procedureHealthPlanId}`, props);
    },
    createProcedureDerivative(data) {
      return base.post(`procedure/procedureDerivatives`, data);
    },
    getProcedureDerivatives(clinicProcedureId, orderBy) {
      const config = {
        params: { clinicProcedureId, orderBy }
      }
      return base.get(`procedure/procedureDerivatives`, config);
    },
    updateProcedureDerivative(procedureDerivativeId, props) {
      return base.put(`procedure/procedureDerivatives/${procedureDerivativeId}`, props);
    },
    deleteProcedureDerivative(procedureDerivativeId) {
      return base.delete(`procedure/procedureDerivatives/${procedureDerivativeId}`);
    },
    createProcedureProduct(data) {
      return base.post(`procedure/procedureProducts`, data);
    },
    getProcedureProducts(clinicProcedureId, orderBy) {
      const config = {
        params: { clinicProcedureId, orderBy }
      }
      return base.get(`procedure/procedureProducts`, config);
    },
    updateProcedureProduct(procedureProductId, props) {
      return base.put(`procedure/procedureProducts/${procedureProductId}`, props);
    },
    deleteProcedureProduct(procedureProductId) {
      return base.delete(`procedure/procedureProducts/${procedureProductId}`);
    },
    getProcedurePrice(clinicProcedureId, healthPlanId, planId) {
      const config = {
        params: { healthPlanId, planId }
      }
      return base.get(`procedure/${clinicProcedureId}/price`, config)
    },
    getAllProcedurePlans(clinicId, clinicProcedureId, healthPlanId) {
      return base.get(`procedure/${clinicId}/${clinicProcedureId}/${healthPlanId}/procedurePlans`)
    },
    getAppointmentProcedureTiss(clinicHealthPlanId, procedureName) {
      return base.get(`tissTableProcedure/getAppointmentProcedure`, {
        params: { clinicHealthPlanId, procedureName }
      })
    },

    // procedure kit templates

    createProcedureKitTemplate(data) {
      return base.post(`procedureKitTemplates`, data);
    },
    getProcedureKitTemplates(clinicProcedureId) {
      return base.get(`procedureKitTemplates/${clinicProcedureId}`);
    },
    updateProcedureKitTemplate(procedureKitTemplateId, props) {
      return base.put(`procedureKitTemplates/${procedureKitTemplateId}`, props);
    },
    deleteProcedureKitTemplate(procedureKitTemplateId) {
      return base.delete(`procedureKitTemplates/${procedureKitTemplateId}`);
    },

    // attendance

    getPendingAttendanceProcedures(page, clinicId) {
      const config = {
        params: { page, clinicId }
      }
      return base.get(`attendance/pendingProcedures`, config);
    },

    // medical records
    getAttendanceMedicalRecord(attendanceId){
      const config = {
        params: { attendanceId }
      }
      return base.get(`attendanceMedicalRecords`, config);
    },

    findLastMedicalRecord(type, patient_id) {
      return base.get('medicalRecords', {
        params: { type, patient_id }
      });
    },

    destroyMedicalRecord(data) {
      return base.delete('medicalRecords', {
        params: { ...data }
      });
    },

    getPatientAttendanceDates(patientId, clinicId, types, medicalRecords){
      const config = {
        params: { patientId, clinicId, types, medicalRecords }
      }
      return base.get(`patientAttendanceDates`, config);
    },

    // TODO REMOVE - USE getAppointmentProcedures
    getAttendanceProcedures(clinicId, patientId, key) {
      const config = {
        params: { clinicId, patientId, key }
      }
      return base.get(`attendance/procedures`, config);
    },

    //appointment
    getAppointmentProcedures(clinicId, patientId, key) {
      const config = {
        params: { clinicId, patientId, key }
      }
      return base.get(`appointment/procedures`, config);
    },
    getPendingAppointmentProducts(page, clinicId, start, end) {
      const config = {
        params: { page, clinicId, start, end }
      }
      return base.get(`appointment/pendingProducts`, config);
    },

    // clinicPlans
    createClinicHealthPlan(data) {
      return base.post(`clinicHealthPlan`, data);
    },
    updateClinicHealthPlan(clinicHealthPlanId, data) {
      return base.put(`clinicHealthPlan/${clinicHealthPlanId}`, data);
    },
    getAllClinicHealthPlans(clinicId) {
      return base.get(`clinicHealthPlan/${clinicId}/all`);
    },
    getClinicHealthPlans(clinicId, page, query, invoicingType) {
      const config = {
        params: { page, query, invoicingType }
      }
      return base.get(`clinicHealthPlan/${clinicId}/healthPlans`, config);
    },
    getClinicPlans(clinicHealthPlanId) {
      return base.get(`clinicHealthPlan/${clinicHealthPlanId}/plans`);
    },
    getClinicHealthPlanByHealthId(clinicId, healthPlanId, withTrashed) {
      return base.get(`clinicHealthPlan/${clinicId}/${healthPlanId}/getByHealthId`, {
        params: { withTrashed }
      });
    },
    findByClinicIdAndInvoicingTissSettingId(clinicId, invoicingTissSettingId) {
      return base.get(`clinicHealthPlan/${clinicId}/${invoicingTissSettingId}/getByInvoicingId`);
    },
    getPatientHealthPlans(clinicId, patientId, types) {
      const config = {
        params: { types }
      }
      return base.get(`clinicHealthPlan/${clinicId}/${patientId}/patientHealthPlans`, config);
    },
    getPatientPlansTissActive(patientId, clinicId) {
      return base.get(`getPatientPlansTissActive/${patientId}/clinic/${clinicId}`)
    },
    getPatientPlansSusActive(patientId, clinicId) {
      return base.get(`getPatientPlansSusActive/${patientId}/clinic/${clinicId}`)
    },
    getPatientPersonHealthPlans(patientId, clinicId) {
      return base.get(`getPatientHealthPlans/${patientId}/clinic/${clinicId}`)
    },
    updateProcedurePlans(procedurePlanId, data) {
      return base.put(`procedurePlans/${procedurePlanId}`, data);
    },

    createForm(data) {
      return base.post(`/forms`, data)
    },


    // Chat --- Conecct in Glauco API(Adonis)

    getDoctorId(cpf, name, email){
        return glaucoApi.get(`/getId?cpf=${cpf}&name=${name}&email=${email}`)
    },
    getChats(doctorId){
        return glaucoApi.get(`/doctor-chats?id=${doctorId}`)
    },
    getMessages(chatId){
        return glaucoApi.get(`/doctor-chats/${chatId}`)
    },
    getFileGlauco(chatId, doctorId, contentType, fileName){
        return glaucoApi.get(`/doctor-chats/${chatId}/getFile?id=${doctorId}&contentType=${contentType}&fileName=${fileName}`)
    },
    uploadFile(chatId, data){
        return glaucoApiUpload.post(`/doctor-chats/${chatId}/upload`, data)
    },


    getCodes(clinicId){
        return glaucoApiAdonis.get(`/access-key/institution/${clinicId}`)
    },
    generateCode(clinicId, data){
        return glaucoApiAdonis.post(`/access-key/institution/${clinicId}/generate`, data)
    },
    revokeCode(keyId){
        return glaucoApiAdonis.put(`/access-key/revoke/${keyId}`)
    },
    activateCode(userId) {
      return glaucoApiAdonis.put(`/access-key/activate/${userId}`);
    },

    // Glauco patient endpoints
    glaucoResume(clinicId) {
      return glaucoApiAdonis.get(`/users/glaucoResume/${clinicId}`);
    },
    getCountGlaucoPatientsWithCompanions(clinicId) {
      return glaucoApiAdonis.get(`/users/patientWithCompanions/${clinicId}`);
    },
    getUserAndGenderGlauco(clinicId) {
      return glaucoApiAdonis.get(`/users/genderAndAgeGroup/${clinicId}`);
    },
    getGlaucoPatients(clinicId, currentPage, filters) {
      return glaucoApiAdonis.get(`/users/patients/${clinicId}?page=${currentPage}`, {
        params: { filters }
      });
    },
    getGlaucoPatientsWithCompanions(clinicId, currentPage, filters) {
      return glaucoApiAdonis.get(`/users/patientsWithCompanions/${clinicId}?page=${currentPage}`, {
        params: { filters }
      });
    },
    getMainDiseasesInGlauco(clinicId) {
      return glaucoApiAdonis.get(`/main-eye-diseases/${clinicId}`);
    },
    glaucoAdherenceTreatmentInyear(clinicId, year) {
      return glaucoApiAdonis.get(`alert/adherence-in-year/${clinicId}?year=${year}`);
    },
    glaucoAdherenceTreatmentInMonth(clinicId, month) {
      return glaucoApiAdonis.get(`alert/adherence-in-month/${clinicId}?month=${month}`);
    },
    searchGlaucoEyeDiseases(query) {
      return glaucoApiAdonis.get('/eye-diseases/search', {
        params: { query }
      });
    },
    glaucoPatientDashboardData(patientId, clinicId) {
      return glaucoApiAdonis.get(`users/dashboard/${patientId}/clinicId/${clinicId}`);
    },
    getPatientAccessKey(patientId) {
      return glaucoApiAdonis.get(`/users/${patientId}/access-key`);
    },
    glaucoUserTreatments(patientId) {
      return glaucoApiAdonis.get(`/user-tratment/users/${patientId}`);
    },
    getPatientAdditionalInfo(patientId) {
      return glaucoApiAdonis.get(`/additional-info/users/${patientId}`);
    },
    getPatientSymptoms(patientId, date, type) {
      return glaucoApiAdonis.get(`/symptoms/users/${patientId}?type=${type}`, {
        params: { date }
      });
    },
    getPatientMedications(patientId) {
      return glaucoApiAdonis.get(`/user-medications/users/${patientId}`);
    },
    getPatientAdherenceInMonth(patientId, month) {
      return glaucoApiAdonis.get(`/alert/adherence-in-month-calendar/user/${patientId}?month=${month}`);
    },
    getPatientAdherenceInDay(patientId, day) {
      return glaucoApiAdonis.get(`/alert/adherence-in-day/user/${patientId}?day=${day}`);
    },
    getGlaucoPatientDiseaseByCpf(cpf) {
      return glaucoApiAdonis.get(`/user-treatment/getAllUserTreatmentsByCpf`, {
        params: { cpf }
      });
    },

    getGlaucoLaserMedications() {
      return glaucoApiAdonis.get(`/medications/getLaserMedications`);
    },
    getGlaucoInjectionMedications() {
      return glaucoApiAdonis.get(`/medications/getInjectionMedications`);
    },

    createUserScheduledMedication(data) {
      return glaucoApiAdonis.post(`/user-scheduled-medication`, data);
    },
    createAlertByUserTreatmentBI(data) {
      return glaucoApiAdonis.post(`/user-scheduled-medication-bi`, data);
    },
    getClinics(){
        return glaucoApi.get('/getClinics')
    },

    // Glauco credits
    getTotalGlaucoCredits(clinicId) {
      return base.get(`glaucoCredit/sumOfCredits/${clinicId}`)
    },
    getGlaucoCredits(clinicId, currentPage) {
      return base.get(`glaucoCredits?page=${currentPage}`, {
        params: { 'clinic_id': clinicId }
      })
    },
    createGlaucoCredits(data) {
      return base.post(`glaucoCredits`, data)
    },


    // FINANCIAL ENDPOINTS
    // DASHBOARD
    getBillsToPayToday(clinicId, bankAccountId) {
      const config = {
          params: { bankAccountId }
      };
      return base.get(`financial/dashboard/${clinicId}/billToPayCountToday`, config);
    },

    getTotalBillsToReceiveToday(clinicId, bankAccountId) {
      const config = {
          params: { bankAccountId }
      };
      return base.get(`financial/dashboard/${clinicId}/totalBillToReceiveToday`, config);
    },

    getTotalBillsToPayToday(clinicId, bankAccountId) {
      const config = {
          params: { bankAccountId }
      };
      return base.get(`financial/dashboard/${clinicId}/totalBillToPayToday`, config);
    },
    getInputOutputMonthly(clinicId, type, month, year, bankAccountId) {
      const config = {
        params: { type, month, year, bankAccountId }
      }
      return base.get(`financial/dashboard/${clinicId}/monthly`, config);
    },
    getMostPopularHealthPlans(clinicId) {
      return base.get(`financial/dashboard/${clinicId}/popularHealthPlans`);
    },
    getMostPopularPaymentMethods(clinicId, bankAccountId) {
      return base.get(`financial/dashboard/${clinicId}/popularPaymentMethods`, bankAccountId);
    },
    getBillsToReceiveExport(clinicId, filters = []) {
      const config = {
        params: { filters }
      }
      return baseXlsx.get(`financial/billsToReceive/export/${clinicId}`, config);
    },
    getBillToPayExport(clinicId, filters = []) {
      const config = {
        params: { filters }
      }
      return baseXlsx.get(`financial/billsToPay/export/${clinicId}`, config);
    },

    // CASH FLOW
    getCashFlow(clinicId, bankAccountId, dateStart, dateEnd) {
      const config = {
          params: { clinicId, bankAccountId, dateStart, dateEnd }
      };
      return base.get(`financial/cashFlow`, config);
    },

    // CONFIG CONTAS BANCÁRIAS
    getBankAccount(clinicId)
    {
      return base.get(`financial/list_bank_account/${clinicId}`);
    },

    createBankAccount(data)
    {
      return base.post('financial/create_bank_account', data);
    },

    updateBankAccount(data)
    {
      return base.post('financial/update_bank_account', data);
    },

    updateStatusBankAccount(data)
    {
      return base.post('financial/update_status_bank_account', data);
    },

    updateDefaultBankAccountEntries(data)
    {
      return base.post('financial/update_default_bank_account_entries', data);
    },

    updateDefaultBankAccountOutband(data)
    {
      return base.post('financial/update_default_bank_account_outband', data);
    },

    deleteBankAccount(id)
    {
      return base.post('financial/delete_bank_account', id);
    },

    // CONFIG CATEGORIAS
    getCategories(clinicId, type, showRemoved, allCategories = false)
    {
      const config = {
        params: { type, showRemoved, allCategories }
      };
      return base.get(`financial/list_categories/${clinicId}`, config);
    },
    getProductCategory(clinicId)
    {
      return base.get(`financial/${clinicId}/getProductCategory`);
    },


    createCategory(data)
    {
      return base.post('financial/create_category', data);
    },

    updateCategory(data)
    {
      return base.post('financial/update_category', data);
    },

    updateStatusCategory(data)
    {
      return base.post('financial/update_status_category', data);
    },

    updateDefaultCategoryAccountsReceivable(data)
    {
      return base.post('financial/update_default_category_accounts_receivable', data);
    },

    updateDefaultCategoryAccountsPayable(data)
    {
      return base.post('financial/update_default_category_accounts_payable', data);
    },

    deleteCategory(id)
    {
      return base.post('financial/delete_category', id);
    },

    // CONFIG MÉTODOS DE PAGAMENTO
    getPaymentMethods(clinicId)
    {
      const config = {
        params: { clinicId }
      }
      return base.get(`financial/paymentMethods`, config);
    },

    createPaymentMethod(data)
    {
      return base.post('financial/paymentMethods', data);
    },

    updatePaymentMethod(id, data)
    {
      return base.put(`financial/paymentMethods/${id}`, data);
    },

    deletePaymentMethod(id)
    {
      return base.delete(`financial/paymentMethods/${id}`);
    },

    deletePaymentMethodInstallment(id)
    {
      return base.delete(`financial/paymentMethodsInstallment/${id}`);
    },

    updateStatusPaymentMethod(data)
    {
      return base.post('financial/update_status_payment_method', data);
    },

    updateDefaultPaymentMethodEntries(data)
    {
      return base.post('financial/update_default_payment_method_entries', data);
    },

    updateDefaultPaymentMethodOutband(data)
    {
      return base.post('financial/update_default_payment_method_outband', data);
    },


    // CONFIG PREFERÊNCIAS
    getPreferences(clinicId)
    {
      return base.get(`financial/list_preferences/${clinicId}`);
    },

    createPreference(data)
    {
      return base.post('financial/create_preference', data);
    },

    updateStatusPreference(data)
    {
      return base.post('financial/update_status_preference', data);
    },

    // CONTAS A RECEBER
    // getBillToReceiveInstallments(clinicId, page, query, category, status_payment, start, end, professional, patient, payment_method, bank_account, period_status) {
    //   const config = {
    //     params: { clinicId, page, query, category, status_payment, start, end, professional, patient, payment_method, bank_account, period_status }
    //   }
    //   return base.get(`financial/billToReceive/installments`, config);
    // },

    getInstallments(clinicId, type, page, query, category, status_payment, start, end, beneficiary, patient, payment_method, bank_account, period_status, period_order, expirationType = null, costCenters = null) {
      const config = {
        params: { clinicId, type, page, query, category, status_payment, start, end, beneficiary, patient, payment_method, bank_account, period_status, period_order, expirationType, costCenters }
      }
      return base.get(`financial/installments`, config);
    },

    getBillToReceive(billToReceiveId) {
      return base.get(`financial/${billToReceiveId}/billToReceive`);
    },


    getProceduresAndPriceFromAppoitment(appointmentId) {
      return base.get(`financial/bills_to_receive/${appointmentId}/proceduresByAppointment`);
    },

    getAttendancesBillToReceive(clinicId) {
      return base.get(`financial/get_attendances/${clinicId}`);
    },

    getPlansBillToReceive() {
      return base.get('financial/get_plans');
    },

    getPaymentMethodsBillToReceive(clinicId) {
      return base.get(`financial/get_payment_methods/${clinicId}`);
    },

    getBankAccountBillToReceive(clinicId) {
      return base.get(`financial/get_bank_account/${clinicId}`);
    },

    createBillToReceive(data) {
      return base.post(`financial/bills_to_receive`, data);
    },

    deleteBillToReceive(billToReceiveId) {
      return base.delete(`financial/bills_to_receive/${billToReceiveId}`);
    },

    updateBillToReceiveTISSFile(billToReceiveId, data) {
      return baseUpload.post(`financial/bills_to_receive/${billToReceiveId}/file`, data);
    },

    getBillToReceiveAppointmentRelation(billToReceiveId) {
      return base.get(`financial/bills_to_receive/${billToReceiveId}/appointment`);
    },

    removeTISSFile(billToReceiveId) {
      return baseUpload.delete(`financial/bills_to_receive/${billToReceiveId}/file`);
    },

    updateBillToReceive(id, data) {
      return base.put(`financial/bills_to_receive/${id}`, data);
    },

    deleteBillToReceive(id) {
      return base.delete(`financial/bills_to_receive/${id}`);
    },

    updateFinancialInstallments(data) {
      return base.post(`financial/update/installments`, { data });
    },

    accountReceive(data) {
      return base.post(`financial/account_receive`, data);
    },



    // CONTAS A PAGAR
    // getBillToPayInstallments(clinicId, page, query, category, status_payment, start, end, professional, patient, payment_method, bank_account, period_status, expirationType) {
    //   const config = {
    //     params: { clinicId, page, query, category, status_payment, start, end, professional, patient, payment_method, bank_account, period_status, expirationType }
    //   }
    //   return base.get(`financial/billToPay/installments`, config);
    // },

    // getBillToPay(billToPayId) {
    //   return base.get(`financial/${billToPayId}/billToPay`);
    // },

    createBillToPay(data) {
      return base.post(`financial/bills_to_pay`, data);
    },

    updateBillToPay(id, data) {
      return base.put(`financial/bills_to_pay/${id}`, data);
    },

    deleteBillToPay(id) {
      return base.delete(`financial/bills_to_pay/${id}`);
    },

    accountPay(data) {
      return base.post(`financial/account_pay`, data);
    },

    // createBillsToPayFile(data, listeners) {
    //   return baseUpload.post('billsToPayFiles', data, listeners)
    // },

    // updateBillsToPayFile(id, data, listeners) {
    //   return base.post(`billsToPayFiles/update/${id}`, data, listeners)
    // },

    // deleteBillsToPayFile(id) {
    //   return base.delete(`billsToPayFiles/${id}`)
    // },

    // Contas a receber/pagar
    getClinicBills(clinicId, page = 1, filters = []) {
      const config = {
        params: { filters }
      };
      return base.get(`financial/bills/${clinicId}?page=${page}`, config);
    },

    // [DEPRECATED] GUIA TISS FINANCEIRO 
    // getTissGuides(clinicId, page, query, healthPlan, statusPayment, start, end, periodStatus, procedure, professional, bankAccount) {
    //   const config = {
    //     params: { clinicId, page, query, healthPlan, statusPayment, start, end, periodStatus, procedure, professional, bankAccount }
    //   }
    //   return base.get(`financial/tissGuides`, config);
    // },
    // getTissGuideByAppointment(appointmentId) {
    //   return base.get(`financial/${appointmentId}/tissGuidesByAppointment`);
    // },
    // createTissGuide(data) {
    //   return base.post(`financial/tissGuides`, data);
    // },
    // updateTissGuide(id, data) {
    //   return base.put(`financial/tissGuides/${id}`, data);
    // },
    // updateProcedureStatus(data) {
    //   return base.post(`financial/tissGuides/updateProcedureStatus`, data);
    // },
    // getTissGuidesExport(clinicId, query, healthPlan, statusPayment, start, end, periodStatus, procedure, professional, bankAccount) {
    //   const config = {
    //     params: { clinicId, query, healthPlan, statusPayment, start, end, periodStatus, procedure, professional, bankAccount }
    //   }
    //   return baseXlsx.get(`financial/tissGuides/export`, config);
    // },
    printTissGuide(guideId, userName, isExpense) {
      return baseFile.get(`printGuide/${guideId}`, {params: {userName, isExpense}})
    },

    // TISS FILES
    createTissGuideFile(data, listeners) {
      return baseUpload.post('tissGuideFiles', data, listeners)
    },
    updateTissGuideFile(id, data, listeners) {
      return base.post(`tissGuideFiles/${id}/update`, data, listeners)
    },
    deleteTissGuideFile(id) {
      return base.delete(`tissGuideFiles/${id}`)
    },

    // GUIAS TISS
    getTissGuideFiles(tissGuideId) {
      return base.get('tissGuideFile', {
        params: { tissGuideId }
      });
    },
    createNewTissGuideFile(data, listeners) {
      return baseUpload.post('tissGuideFile', data, listeners)
    },
    createNewSusBatchFile(data, listeners) {
      return baseUpload.post('susBatchFile', data, listeners)
    },
    updateNewTissGuideFile(id, data, listeners) {
      return baseUpload.post(`tissGuideFile/update/${id}`, data, listeners)
    },
    updateNewSusBatchFile(id, data, listeners) {
      return baseUpload.post(`susBatchFile/update/${id}`, data, listeners)
    },
    deleteNewTissGuideFile(id) {
      return base.delete(`tissGuideFile/${id}`)
    },
    deleteNewSusBatchFile(id) {
      return base.delete(`susBatchFile/${id}`)
    },
    getTissGuidesByAppointment(appointmentId) {
      const config = {
        params: { appointmentId }
      }
      return base.get('tissGuide', config)
    },
    findByGuideIdReport(guideId) {
      return base.get(`tissGuide/guideReport/${guideId}`)
    },
    findTissGuideByGuideId(guideId) {
      return base.get(`tissGuide/guide/${guideId}`)
    },
    getTissByClinicId(clinicId, currentPage, filters = []) {
      const filter = {
        params: { filters }
      }
      return base.get(`tissGuide/clinic/${clinicId}?page=${currentPage}`, filter )
    },
    getTissGuideById(id) {
      return base.get(`tissGuide/${id}`)
    },
    getSusGuideById(id) {
      return base.get(`sus/guide/${id}`)
    },
    updateNewTissGuide(id, data) {
      return base.put(`tissGuide/${id}`, data)
    },
    updateNewSusGuide(id, data) {
      return base.put(`sus/guide/${id}`, data)
    },
    removeGuideFromBatch(id, data) {
      return base.post(`sus/guide/${id}/batch/remove`, data)
    },
    setConference(id, data) {
      return base.put(`sus/guide/conference/${id}`, data)
    },
    updateItemsSituations(data) {
      return base.put('tissGuideUpdateItemsSituations', data)
    },
    duplicateSPSADTGuide(id){
      return base.post(`tissGuide/${id}/duplicateSpSadt`) 
    },
    getTissGuideAudits(tissGuideId) {
      return base.get('tissGuideAudit', {
        params: {
          tissGuideId
        }
      });
    },
    getSusGuideAudits(susGuideId) {
      return base.get('susGuideAudit', {
        params: {
          susGuideId
        }
      });
    },
    getProfessionalAudit(tissGuideId, query) {
      return base.get(`tissGuideAudit/professional/${tissGuideId}`, {
        params: {
          'querySearch': query
        }
      });
    },
    // CBHPM
    getCBHPMAnnouncements() {
      return base.get('cbhpmAnnouncements');
    },
    listCBHPMMTables() {
      return base.get('listCBHPMMTables');
    },
    getTissOtherTables(clinicHealthPlanId, { name, type }) {
      return base.get(`getTissOtherTables/${clinicHealthPlanId}`, {
        params: { name, type }
      });
    },

    createConsultationGuide(data) {
      return base.post(`tissGuideConsultation`, data);
    },
    updateConsultationGuide(id, data) {
      return base.put(`tissGuideConsultation/${id}`, data);
    },
    createSPSADTGuide(data) {
      return base.post(`tissGuideSpSadt`, data);
    },
    updateSPSADTGuide(id, data) {
      return base.put(`tissGuideSpSadt/${id}`, data);
    },
    createSusGuide(data) {
      return base.post(`sus/guide`, data);
    },
    createHospitalizationRequestGuide(data) {
      return base.post(`tissGuideHospitalizationRequest`, data);
    },
    updateHospitalizationRequestGuide(id, data) {
      return base.put(`tissGuideHospitalizationRequest/${id}`, data);
    },
    createHospitalizationSummaryGuide(data) {
      return base.post(`tissGuideHospitalizationSummary`, data);
    },
    updateHospitalizationSummaryGuide(id, data) {
      return base.put(`tissGuideHospitalizationSummary/${id}`, data);
    },
    createFeeGuide(data) {
      return base.post(`tissGuideFee`, data);
    },
    updateFeeGuide(id, data) {
      return base.put(`tissGuideFee/${id}`, data);
    },
    createTissGuideOtherExpense(data) {
      return base.post(`tissGuideOtherExpense`, data);
    },
    updateTissGuideOtherExpense(id, data) {
      return base.put(`tissGuideOtherExpense/${id}`, data);
    },
    updateManyTissProviderGuide(data) {
      return base.put(`updateManyTissGuideProvider`, data);
    },

    hasActivatedTissProcedures(tissSettingId) {
      return base.get(`hasActivatedTissProcedures/${tissSettingId}`)
    },
    getTissTableProcedures(id, query, clinicId = null) {
      return base.get('getTissTableProcedures', {
        params: { id, query, clinicId }
      })
    },
    getTableProceduresPaginate(id, query, status, page) {
      return base.get(`getTableProceduresPaginate?page=${page}`, {
        params: { id, query, status }
      })
    },
    getTissTableImportProcedures(tissTableImportId, query, status, page) {
      return base.get(`tableImport/${tissTableImportId}/tissImportedProcedures`, {
        params: { query, status, page }
      })
    },
    getTissTableImportedExpenses(page, tissTableImportId, query, status) {
      return base.get(`tableImport/${tissTableImportId}/tissImportedExpenses`, {
        params: { query, status, page }
      })
    },
    updateTissTableImportedExpenses(id, data) {
      return base.put(`tissImportedExpenses/${id}`, data)
    },
    deleteTissTableImportedExpenses(id) {
      return base.delete(`tissImportedExpenses/${id}`)
    },
    updateTissTableImportProcedure(id, data) {
      return base.put(`tissTableImportProcedures/${id}`, data)
    },
    deleteTissGuideItem(id) {
      return base.delete(`tissGuideItem/${id}`)
    },
    deleteTissGuideProfessional(id) {
      return base.delete(`tissGuideProfessional/${id}`)
    },
    deleteTissGuideMaterialProcedure(id) {
      return base.delete(`tissGuideMaterialsProcedure/${id}`)
    },
    billingSummary(clinicId, data) {
      return base.get(`tissGuide/billingSummary/${clinicId}`, {
        params: data
      });
    },
    billingReport(clinicId, data) {
      return base.get(`tissGuide/billingReport/${clinicId}`, {
        params: data
      });
    },
    susBillingSummary(clinicId, params) {
      return base.get(`sus/guide/billingSummary/${clinicId}`, { params });
    },
    susBillingReport(clinicId, params) {
      return base.get(`sus/guide/billingReport/${clinicId}`, { params });
    },
    financialByHealthPlans(clinicId, data) {
      return base.get(`tissGuide/financialByHealthPlans/${clinicId}`, {
        params: data
      });
    },
    getInvoicingTissNumbers(healthPlanId, clinicId, findClosed) {
      return base.get(`tissInvoice/${healthPlanId}/${clinicId}/getInvoiceNumbers`, {
        params: { findClosed }
      });
    },
    getInvoicingSusNumbers(healthPlanId, clinicId, findClosed) {
      return base.get(`susInvoice/${healthPlanId}/${clinicId}/getInvoiceNumbers`, {
        params: { findClosed }
      });
    },
    getGuidesByHealthPlanId(healthPlanId) {
      return base.get(`tiss-guides/health-plan/${healthPlanId}`);
    },
    updateInvoiceIdManyGuides(payload) {
      return base.patch('tiss-guides/tiss-invoices/bulk-update', payload);
    },
    getNextNumberByHealthPlanId(clinicHealthPlanId,currentNumber){
      return base.get(`tiss-invoice/${clinicHealthPlanId}/nextInvoiceNumber`);
    },
    createInvoicingTissInvoice(data) {
      return base.post(`tissInvoice`, data);
    },
    updateInvoicingTissInvoice(id, data) {
      return base.put(`tissInvoice/${id}`, data);
    },
    getInvoicingTissInvoices(clinicId, filters, page) {
      const config = {
        params: { clinicId, filters }
      }
      return base.get(`tissInvoice?page=${page}`, config);
    },
    getTissBatches(clinicId, page, status, filters = []) {
      return base.get(`tissBatch?page=${page}`, {
        params: { clinicId, status, filters }
      });
    },
    // getTissBatchesInfo(clinicId, page, filters = []) {
    //   return base.get(`getBatchesInfo?page=${page}`, {
    //     params: { clinicId, filters }
    //   });
    // },
    findTissBatchById(id) {
      return base.get(`tissBatch/${id}`);
    },
    findSusBatchById(id) {
      return base.get(`susBatch/${id}`);
    },
    deleteTissBatch(id) {
      return base.delete(`tissBatch/${id}`);
    },
    updateTissBatch(id, data){
      return base.put(`tissBatch/${id}`, data);
    },
    updateManyTissBatches(data) {
      return base.put(`updateManyTissBatches`, data);
    },
    listBatchestoPrint(clinicId, filters, page) {
      return base.get(`listBatchestoPrint/${clinicId}?page=${page}`, {
        params: { filters }
      });
    },
    printTissBatches(data) {
      return base.post(`printBatches`, data);
    },
    getTissGuideByInvoiceId(invoiceId, page, filters) {
      const config = {
        params: { filters }
      }
      return base.get(`tissGuide/invoice/${invoiceId}?page=${page}`, config);
    },
    getHospitalizationRequestGuides(patientId, invoicingTissSettingId) {
      return base.get(`tissGuide/getHospitalizationRequestGuides/${patientId}/settings/${invoicingTissSettingId}`);
    },
    getReferencedGuidesForExpense(patientId, invoicingTissSettingId) {
      return base.get(`tissGuide/getReferencedGuidesForExpense/${patientId}/settings/${invoicingTissSettingId}`)
    },
    deleteTissGuide(id, decrease = false) {
      return base.delete(`tissGuide/${id}/${decrease}`);
    },
    getTissGuideByBatchId(batchId, page, filters = []) {
      const config = {
        params: { filters }
      }
      return base.get(`tissGuide/batch/${batchId}?page=${page}`, config);
    },
    fetchGuidesForClinicWithFilters(batchId, page, filters = []) {
      const config = {
        params: { filters }
      }
      return base.get(`tissGuide/batch/${batchId}/summary?page=${page}`, config);
    },
    getSusGuideByBatchId(batchId, page, filters = []) {
      const config = {
        params: { filters }
      }
      return base.get(`sus/batch/${batchId}?page=${page}`, config);
    },
    batchNumberExists(batchNumber, clinicHealthPlanId) {
      return base.get(`batchNumberExists`, {
        params: { 'batch_number': batchNumber, 'clinic_health_plan_id': clinicHealthPlanId }
      })
    },
    invoiceNumberExist(invoiceNumber, clinicHealthPlanId) {
      return base.get(`invoiceNumberExist`, {
        params: { 'invoice_number': invoiceNumber, 'clinic_health_plan_id': clinicHealthPlanId }
      })
    },
    guideNumberExist(guideNumber, clinicHealthPlanId) {
      return base.get(`guideNumberExist`, {
        params: {
          'guide_number': guideNumber,
          'clinic_health_plan_id': clinicHealthPlanId
        }
      })
    },
    getConflictedGuideNumbers(originalProviderGuide, providerGuideEdit, clinicHealthPlanId, guideType) {
      return base.get(`getConflictedGuideNumbers`, {
        params: {
          'original_provider_guide': originalProviderGuide,
          'provider_guide_edit': providerGuideEdit,
          'clinic_health_plan_id': clinicHealthPlanId,
          'guide_type': guideType
        }
      })
    },
    findTissGuideByIdToReport(id) {
      return base.get(`tissGuide/findByIdToReport/${id}`);
    },
    getCurrentBatchNumber(tissInvoiceId, clinicHealthPlanId) {
      return base.get('getCurrentBatchNumber', {
        params: {
          'tiss_invoice_id': tissInvoiceId,
          'clinic_health_plan_id': clinicHealthPlanId
        }
      });
    },
    getTotalBatchesToCreate(tissInvoiceId, clinicHealthPlanId) {
      return base.get('getTotalBatchesToCreate', {
        params: {
          'tiss_invoice_id': tissInvoiceId,
          'clinic_health_plan_id': clinicHealthPlanId
        }
      });
    },
    storeTissBatch(data) {
      return base.post(`tissBatch`, data);
    },
    storeTissBatchByGuideIds(data) {
      return base.post(`tissBatch/storeByGuideIds`, data);
    },
    tissBatchXML(data) {
      return base.post(`tissBatchXML`, data);
    },
    importTissBatchXML(clinicId, data) {
      return baseUpload.post(`tissBatchXML/importXMl/${clinicId}`, data);
    },
    getImportTissXML(clinicId) {
      return base.get(`getXmlImportByClinicId/${clinicId}`);
    },

    // TUSS
    getTissImportedExpenses(query, table, clinicHealthPlanId, cd, filters=[]) {
      return base.get('tissImportedExpenses', {
        params: { query, table, clinic_health_plan_id: clinicHealthPlanId, cd, filters }
      })
    },
    getTussMaterial(query, table, clinicHealthPlanId) {
      return base.get('tussMaterial', {
        params: { query, table, clinic_health_plan_id: clinicHealthPlanId }
      })
    },

    getTussMedicine(query, table, clinicHealthPlanId) {
      return base.get('tussMedicine', {
        params: { query, table, clinic_health_plan_id: clinicHealthPlanId }
      })
    },

    getTussTaxesDailysGases(query, table, clinicHealthPlanId) {
      return base.get('tussTaxesDailysGases', {
        params: { query, table, clinic_health_plan_id: clinicHealthPlanId }
      })
    },

    // Centro cirúrgico
    getGeneralSheetTemplates(clinicId) {
      return base.get(`generalSheetTemplate`, {
        params: { clinic_id: clinicId }
      })
    },
    createGeneralSheetTemplate(data, listeners) {
      return baseUpload.post(`generalSheetTemplate`, data, listeners)
    },
    updateGeneralSheetTemplate(id, data) {
      return baseUpload.post(`generalSheetTemplates/file/${id}`, data)
    },
    updateGeneralSheetTemplateActive(id, data) {
      return base.put(`generalSheetTemplate/${id}`, data)
    },
    deleteGeneralSheetTemplate(id) {
      return base.delete(`generalSheetTemplate/${id}`)
    },
    getSurgeryRecords(surgeryInformationId, clinicId) {
      return base.get(`surgeryRecords`, {
        params: { surgery_information_id: surgeryInformationId, clinic_id: clinicId }
      })
    },
    updateSurgeryRecord(id, data) {
      return base.put(`surgeryRecords/${id}`, data)
    },
    getSurgeryAdmissionForms(surgeryInformationId, clinicId) {
      return base.get(`surgeryAdmissionForms`, {
        params: { surgery_information_id: surgeryInformationId, clinic_id: clinicId }
      })
    },
    updateSurgeryAdmissionForm(id, data) {
      return base.put(`surgeryAdmissionForms/${id}`, data)
    },
    getAdmissionFormTemplates(clinicId) {
      return base.get(`admissionFormTemplate`, {
        params: { clinic_id: clinicId }
      })
    },
    updateAdmissionFormTemplate(id, data) {
      return base.put(`admissionFormTemplate/${id}`, data)
    },
    getPatientPreparationTemplates(clinicId) {
      return base.get(`patientPreparationTemplate`, {
        params: { clinic_id: clinicId }
      })
    },
    createPatientPreparationTemplate(data) {
      return base.post(`patientPreparationTemplate`, data)
    },
    updatePatientPreparationTemplate(id, data) {
      return base.put(`patientPreparationTemplate/${id}`, data)
    },
    bulkUpdatePatientPreparationTemplate(data) {
      return base.post(`patientPreparationTemplate/bulkUpdate`, data)
    },
    deletePatientPreparationTemplate(id) {
      return base.delete(`patientPreparationTemplate/${id}`)
    },
    getSurgeryPatientPreparations(surgeryInformationId, clinicId) {
      return base.get(`surgeryPatientPreparations`, {
        params: { surgery_information_id: surgeryInformationId, clinic_id: clinicId }
      })
    },
    updateSurgeryPatientPreparation(id, data) {
      return base.put(`surgeryPatientPreparations/${id}`, data)
    },
    getClinicSurgeryRooms(clinicId) {
      return base.get(`getClinicSurgeryRooms/${clinicId}`)
    },
    getSurgery(surgeryId) {
      return base.get(`getSurgery/${surgeryId}`)
    },
    getSurgeryInformationByAppointment(appointmentId) {
      return base.get(`surgeryInformations-appointment/${appointmentId}`)
    },
    createSurgeryInformation(data) {
      return base.post(`surgeryInformations`, data)
    },
    updateSurgeryInformation(id, data) {
      return base.put(`surgeryInformations/${id}`, data)
    },
    createSurgeryInformationComment(data) {
      return base.post(`surgeryInformationComments`, data)
    },
    updateSurgeryInformationComment(id, data) {
      return base.put(`surgeryInformationComments/${id}`, data)
    },
    surgeryInformationComments(surgeryInformationId) {
      return base.get(`surgeryInformationComments`, {
        params: { surgery_information_id: surgeryInformationId }
      })
    },
    saveSurgeryInformationComment(data) {
      return base.post(`surgeryInformationComment/saveComments`, data)
    },
    getSurgeryInformationProcedures(surgeryInformationId) {
      return base.get(`surgeryInformationProcedures`, {
        params: { surgery_information_id: surgeryInformationId }
      })
    },
    getSurgeryProceduresProducts(surgeryInformationId) {
      return base.get(`surgeryProceduresProducts`, {
        params: { surgery_information_id: surgeryInformationId }
      })
    },
    surgeryInformationProceduresListWithRelations(surgeryInformationId) {
      return base.get(`surgeryInformationProcedures-list/${surgeryInformationId}`)
    },
    updateSurgeryInformationProcedure (id, data) {
      return base.put(`surgeryInformationProcedure/${id}`, data)
    },
    saveSurgeryInformationProcedure(data) {
      return base.post(`surgeryInformationProcedure/saveProcedure`, data)
    },
    hasAppointmentOnPeriod(clinicId, roomId, start, end, filters=null) {
      return base.get(`hasAppointmentOnPeriod/clinic/${clinicId}/room/${roomId}`, {
        params: { start, end, filters }
      })
    },
    createRoomReservation(data) {
      return base.post(`roomReservations`, data)
    },
    updateRoomReservation(id, data) {
      return base.put(`roomReservations/${id}`, data)
    },
    deleteRoomReservation(id) {
      return base.delete(`roomReservations/${id}`)
    },
    printSurgicalMap(clinicId, roomIds, filters={}) {
      return base.get(`printSurgicalMap/${clinicId}`, {
        params: { room_ids: roomIds, filters }
      })
    },
    getDailySurgeries (clinicId, filters, page) {
      const config = {
        params: { clinicId, filters, page }
      };
      return base.get('getTodaysSurgeries', config)
    },
    getSurgeryItems (surgeryInformationId) {
      return base.get(`getSurgeryItems/${surgeryInformationId}`)
    },
    createSurgeryItem(data) {
      return base.post(`surgeryItem`, data)
    },
    updateSurgeryItem(id, data) {
      return base.put(`surgeryItem/${id}`, data)
    },
    deleteSurgeryItem(id) {
      return base.delete(`surgeryItem/${id}`)
    },
    getSurgeryProductsTemplates(surgeryInformationId) {
      return base.get(`surgery/products/templates/get/${surgeryInformationId}`)
    },
    storeSurgeryProductsTemplates(data) {
      return base.post(`surgery/products/templates`, data)
    },
    storeManySurgeryProductsTemplates(data=[]) {
      return base.post(`surgery/products/templates/storeMany`, data)
    },
    deleteSurgeryProductsTemplate(id) {
      return base.delete(`surgery/products/templates/${id}`)
    },
    deleteSurgeryProductsTemplatesByKitTemplateId(kitTemplateId) {
      return base.post(`surgery/products/templates/deleteByKitTemplate/${kitTemplateId}`)
    },

    // CONTROLE DE PLANOS
    getPlanControlInstallments(clinicId, page, query, category, status_payment, start, end, periodStatus, professional, patient, payment_method, bank_account, period_status) {
      const config = {
        params: { clinicId, page, query, category, status_payment, start, end, periodStatus, professional, patient, payment_method, bank_account, period_status }
      }
      return base.get(`financial/planControl/installments`, config);
    },

    confirmManagerSelected(data) {
      return base.post(`financial/confirm_manager_selected`, data);
    },

    updateBillToReceiveProcedure(billToReceiveProcedureId, data) {
      return base.put(`financial/billToReceiveProcedures/${billToReceiveProcedureId}`, data);
    },

    //Relatórios gerenciais
    getManagementReport(data, currentPage) {
      const request = {
        params: { data }
      }
      return base.get(`managementReports?page=${currentPage}`, request);
    },
    
    getManagementReportResume(data) {
      const request = {
        params: { data }
      }
      return base.get(`managementReports/resume`, request);
    },

    getManagementReportsExport(data) {
      const request = {
        params: { data }
      }
      return baseXlsx.get('managementReports/export', request);
    },

    // PROTOCOLOS
    createProtocol(data) {
      return base.post('protocols', data);
    },

    updateProtocol(id, data) {
      return base.put(`protocols/${id}`, data);
    },

    deleteProtocol(id) {
      return base.delete(`protocols/${id}`);
    },

    createProtocolMedicine(data) {
      return base.post('protocolMedicines', data);
    },

    updateProtocolMedicine(id, data) {
      return base.put(`protocolMedicines/${id}`, data);
    },

    deleteProtocolMedicine(id) {
      return base.delete(`protocolMedicines/${id}`);
    },

    // SURGICAL NOTES
    createSurgical(data) {
      return base.post('surgicalNote', data);
    },

    deleteSurgical(id) {
      return base.delete(`surgicalNote/${id}`);
    },

    updateSurgical(id, data) {
      return base.put(`surgicalNote/${id}`, data)
    },

    getSurgicalNotes(professionalId) {
      const config = {
        params: { professionalId }
      }
      return base.get('surgicalNote', config);
    },
    findSurgical(id) {
      return base.get(`surgicalNote/${id}`);
    },
    getAllSurgicalNotesFromProfessional(professionalId) {
      return base.get(`surgicalNote/${professionalId}/getAllFromProfessional`);
    },

    createFavorite(data) {
      return base.post(`protocolFavorites`, data);
    },

    updateFavorite(id, data) {
      return base.put(`protocolFavorites/${id}`, data);
    },

    getProtocols(tab, clinicId, query, type, via, professional, pharmacist_name, currentPage, person_id) {
      const config = {
        params: { tab, clinicId, query, type, via, professional, pharmacist_name, person_id }
      }
      return base.get(`protocols?page=${currentPage}` ,config)
    },

    getFillingOptions(fieldName){
      const config = {
        params: {fieldName}
      }
      return base.get('fillingOptions', config)
    },

     // CONSULTAS PERSONALIZADAS
    getForms(clinic_id, professional_id, type) {
      const config = {
        params: {clinic_id, professional_id, type}
      }
      return base.get('forms', config);
    },

    getMedicalReportForm() {
      return base.get(`forms/medicalReport`);
    },

    cancelAttendance(attendanceId) {
      return base.patch(`attendances/${attendanceId}/cancel`);
    },

    getForm(id) {
      return base.get(`forms/${id}`);
    },

    createCustomForm(data) {
      return base.post('forms', data)
    },

    updateForm(id, data) {
      return base.put(`forms/${id}`, data);
    },

    deleteForm(id) {
      return base.delete(`forms/${id}`);
    },

    //Academy
    getCoursers(page, query, all = false) {
      const config = {
        params: { page, query, all }
      }
      return base.get(`academy/courses`, config)
    },
    showCourse(id) {
      return base.get(`academy/courses/${id}`)
    },
    createCourse(props) {
      return base.post(`academy/courses`, props);
    },
    saveCourseThumbnail(courseId, data) {
      return baseUpload.post(`academy/courses/${courseId}/thumbnail`, data);
    },
    updateCourse(id, props) {
      return base.put(`academy/courses/${id}`, props);
    },
    deleteCourse(id) {
      return base.delete(`academy/courses/${id}`);
    },
    deleteModule(id) {
      return base.delete(`academy/modules/${id}`);
    },
    deleteVideoClass(id) {
      return base.delete(`academy/videoClasses/${id}`);
    },
    getProgressByCourse(courseId, personId) {
      const config = {
        params: { courseId, personId }
      }
      return base.get(`academy/progressByCourse`, config)
    },
    getVideoClassProgress(videoClassId, personId) {
      const config = {
        params: { videoClassId, personId }
      }
      return base.get(`academy/videoClass/progress`, config)
    },
    createOrUpdateVideoClassProgress(props) {
      return base.post(`academy/progress`, props);
    },
    createVideoClassComment(props) {
      return base.post(`academy/comments`, props);
    },
    updateVideoClassComment(id, props) {
      return base.put(`academy/comments/${id}`, props);
    },
    deleteVideoClassComment(id) {
      return base.delete(`academy/comments/${id}`);
    },
    getVideoClassComments(videoClassId, offset) {
      const config = {
        params: { videoClassId, offset }
      }
      return base.get(`academy/comments`, config)
    },
    createOrRemoveVideoCommentReaction(props) {
      return base.post(`academy/commentReactions`, props);
    },
    getReaction(personId, videoClassCommentId) {
      const config = {
        params: { personId, videoClassCommentId }
      }
      return base.get(`academy/reaction`, config)
    },

    // Course Dashboard
    getUniqueViews(courseId) {
      return base.get(`academy/courseDashboard/${courseId}/getUniqueViews`)
    },
    getCommentsCount(courseId) {
      return base.get(`academy/courseDashboard/${courseId}/getCommentsCount`)
    },
    getCommentReactionsCount(courseId) {
      return base.get(`academy/courseDashboard/${courseId}/getCommentReactionsCount`)
    },
    getAdoptionPercent(courseId) {
      return base.get(`academy/courseDashboard/${courseId}/getAdoptionPercent`)
    },
    getVideoClassPolarArea(courseId) {
      return base.get(`academy/courseDashboard/${courseId}/getVideoClassPolarArea`)
    },
    getVideoClassStatusBar(courseId) {
      return base.get(`academy/courseDashboard/${courseId}/getVideoClassStatusBar`)
    },

    // Print settings
    getPrintSettings(clinicId) {
      return base.get(`clinic/${clinicId}/printSettings`)
    },
    updatePrintSettings(clinicPrintSettingId, data) {
      return base.put(`clinic/printSettings/${clinicPrintSettingId}`, data)
    },
    createPrintSettings(data) {
      return base.post(`clinic/printSettings`, data)
    },
    deletePrintSettings(clinicPrintSettingId) {
      return base.delete(`clinic/printSettings/${clinicPrintSettingId}`)
    },
    getPrintableDocuments() {
      return base.get(`documents/printable`)
    },
    createSettingPreviewDoc(data) {
      return baseFile.post('document/create-setting-preview-doc', data)
    },

    // Financial

    getBill(billId) {
      return base.get(`financial/${billId}/bill`);
    },
    createBill(data) {
      return base.post(`financial/bills`, data);
    },
    updateWarehouseEntryBill(warehouseEntryId,data) {
      return base.put(`financial/warehouse/entry/bills/${warehouseEntryId}`, data);
    },
    updateBill(id, data) {
      return base.put(`financial/bills/${id}`, data);
    },
    updateBillConferred(id, data) {
      return base.patch(`financial/bills/${id}/conferred`, data)
    },
    deleteBill(id) {
      return base.delete(`financial/bills/${id}`);
    },
	deleteBillRecurrence(id, recurly) {
		return base.patch(`financial/bills/${id}`, {recurly});
	},
    deletePayment(id) {
      return base.delete(`financial/payments/${id}`);
    },
    getBillIdFromAppointmentId(appointmentId) {
      return base.get(`financial/appointment/${appointmentId}/getBillId`);
    },
    createBillFile(data, listeners) {
      return baseUpload.post('financial/billFiles', data, listeners)
    },
    updateBillFile(id, data, listeners) {
      return base.post(`financial/billFiles/update/${id}`, data, listeners)
    },
    deleteBillFile(id) {
      return base.delete(`financial/billFiles/${id}`)
    },
    updateInstallment(id, data) {
      return base.put(`financial/installments/${id}`, data);
    },
    updateInstallmentConferred(id, data) {
      return base.patch(`financial/installments/${id}/conferred`, data);
    },
    getBillsExport(type, clinicId, query, category, status_payment, start, end, professional, patient, payment_method, bank_account, period_status, period_order, expirationType = null) {
      const config = {
        params: { type, clinicId, query, category, status_payment, start, end, professional, patient, payment_method, bank_account, period_status, period_order, expirationType }
      }
      return baseXlsx.get(`financial/billsExport`, config);
    },
    getCostCenters(clinicId) {
      return base.get(`financial/costCenters/${clinicId}`);
    },
    createCostCenter(data) {
      return base.post(`financial/costCenters`, data);
    },
    updateCostCenter(id, data) {
      return base.put(`financial/costCenters/${id}`, data);
    },
    deleteCostCenter(id) {
      return base.delete(`financial/costCenters/${id}`);
    },
    setDefaultCostCenter(id){
      return base.put(`financial/costCenters/setDefault/${id}`)
    },
    getBillCostCenters(billId) {
      return base.get(`financial/billCostCenters/${billId}`);
    },

    getBeneficiaries(clinicId, filters = {}, page=1) {
      const request = {
        params: { filters }
      }
      return base.get(`financial/getBeneficiaries/${clinicId}?page=${page}`, request);
    },
    getBeneficiariesAndRoles(data) {
      return base.post(`financial/getBeneficiariesAndRoles`, data);
    },
    getBeneficiariesWithRules(clinicId) {
      return base.get(`financial/getBeneficiariesWithRules/${clinicId}`);
    },
    getBeneficiariesByClinicId(clinicId) {
      return base.get(`beneficiareies/getBeneficiariesByClinicId/${clinicId}`);
    },
    getBeneficiary(data) {
      return base.get(`financial/beneficiary/${data}`);
    },
    createBeneficiary(data) {
      return base.post(`financial/beneficiary`, data);
    },
    updateBeneficiary(id, data) {
      return base.put(`financial/beneficiary/${id}`, data);
    },
    deleteBeneficiary(id) {
      return base.delete(`financial/beneficiary/${id}`);
    },
    getBeneficiaryByPersonId(id){
      return base.get(`financial/getBeneficiaryByPersonId/${id}`);
    },
    getBeneficiaryById(id){
      return base.get(`financial/getBeneficiaryById/${id}`);
    },

    getCostCenters(clinicId) {
      return base.get(`financial/costCenters/${clinicId}`);
    },
    createCostCenter(data) {
      return base.post(`financial/costCenters`, data);
    },
    updateCostCenter(id, data) {
      return base.put(`financial/costCenters/${id}`, data);
    },
    deleteCostCenter(id) {
      return base.delete(`financial/costCenters/${id}`);
    },
    setDefaultCostCenter(id){
      return base.put(`financial/costCenters/setDefault/${id}`)
    },
    getBillCostCenters(billId) {
      return base.get(`financial/billCostCenters/${billId}`);
    },

    //CRM
    getCrms(personId) {
      return base.get(`crm/${personId}/getByPersonId`)
    },

    //RQE
    getRqes(personId) {
      return base.get(`rqe/${personId}/getByPersonId`)
    },

    //PublicShedule
    getPublicScheduleByClinicId(clinicId) {
      return base.get(`public-schedule/${clinicId}/getByClinicId`)
    },
    createPublicSchedule(data) {
      return base.post(`public-schedule`, data)
    },
    updatePublicSchedule(id, data) {
      return base.put(`public-schedule/${id}`, data)
    },

    getProfessionalsByClinicId(clinicId, view){
      return base.get(`public-schedule-professionals/${clinicId}/getByClinicId/${view}`)
    },
    createPublicScheduleProfessional(data) {
      return base.post(`public-schedule-professional`, data)
    },
    updatePublicScheduleProfessional(id, data) {
      return base.put(`public-schedule-professional/${id}`, data)
    },

    getPublicScheduleProceduresByProfessionalId(professionalId){
      return base.get(`public-schedule-procedures/${professionalId}/getByProfessionalId`)
    },
    createPublicScheduleProfessionalProcedures(data) {
      return base.post(`public-schedule-procedures`, data)
    },
    updatePublicScheduleProfessionalProcedures(id, data) {
      return base.put(`public-schedule-procedures/${id}`, data)
    },
    deletePublicScheduleProfessionalProcedures(id) {
      return base.delete(`public-schedule-procedures/${id}`)
    },

    // Solicitação de exames
    getExamRequestTemplates(filters={}, page=1) {
      const request = {
        params: { filters }
      }
      return base.get(`examRequestTemplate?page=${page}`, request);
    },
    createExamRequestTemplates(data) {
      return base.post(`examRequestTemplate`, data);
    },
    updateExamRequestTemplates(id, data) {
      return base.put(`examRequestTemplate/${id}`, data);
    },
    deleteExamRequestTemplates(id) {
      return base.delete(`examRequestTemplate/${id}`);
    },


    // Features
    getAllFeatures() {
      const config = {
        params: {}
      }
      return base.get(`features`, config);
    },
    featureIsActive(key) {
      return base.get(`features/${key}/active`);
    },
    getClinicFeatures(page, search) {
      const config = {
        params: { page, search }
      }
      return base.get(`clinicFeatures`, config);
    },

    createFeature(data) {
      return base.post(`features`, data);
    },
    createClinicFeature(data) {
      return base.post(`clinicFeatures`, data);
    },

    updateFeature(id, data) {
      return base.put(`features/${id}`, data);
    },
    updateClinicFeature(id, data) {
      return base.put(`clinicFeatures/${id}`, data);
    },

    deleteFeature(id) {
      return base.delete(`features/${id}`);
    },
    deleteClinicFeature(id) {
      return base.delete(`clinicFeatures/${id}`);
    },


    // Painel de chamadas

    getCallPanelDepartments(clinicId, filters={}) {
      const request = {
        params: { filters }
      }
      return base.get(`callPanel/${clinicId}/departments`, request);
    },
    getCallPanelGroups(clinicId, filters={}) {
      const request = {
        params: { filters }
      }
      return base.get(`callPanel/${clinicId}/groups`, request);
    },
    getCallPanelGroupDepartments(panelId) {
      return base.get(`callPanel/${panelId}/groupDepartments`);
    },
    getCallPanelCheckIn(personId) {
      return base.get(`callPanel/${personId}/checkIns`);
    },
    getCallPanelSettings(clinicId) {
      return base.get(`callPanel/${clinicId}/settings`)
    },
    getCurrentPassword(departmentId, listenerId) {
      const config = {
        params: { listenerId }
      }
      return base.get(`callPanel/${departmentId}/current`, config)
    },
    getCurrentPasswordFromClinic(clinicId) {
      return base.get(`callPanel/${clinicId}/clinic/current`)
    },
    getCurrentPasswordFromDepartments(departments) {
      return base.post(`callPanel/departments/current`, departments)
    },

    getCalledPasswords(page, departmentId) {
      const config = {
        params: { page }
      }
      return base.get(`callPanel/${departmentId}/calledPasswords`, config)
    },
    getNextCallPanelPasswords(departmentId) {
      return base.get(`callPanel/${departmentId}/nextPasswords`)
    },
    getClinicCalledPanelPassword(clinicId) {
      return base.get(`callPanel/${clinicId}/clinicCalledPasswords`)
    },

    createCallPanelDepartment(data) {
      return base.post(`callPanel/departments`, data);
    },
    createCallPanelGroup(data) {
      return base.post(`callPanel/groups`, data);
    },
    createCallPanelCheckIn(data) {
      return base.post(`callPanel/checkIns`, data);
    },
    createCallPanelPassword(data) {
      return base.post(`callPanel/passwords`, { ...data, tabId: window.sessionStorage.getItem('tabId') });
    },
    batchCreateCallPanelPassword(data) {
      return base.post(`callPanel/passwords/batchCreate`, { ...data, tabId: window.sessionStorage.getItem('tabId') });
    },
    createCallPanelSettings(data) {
      return base.post('callPanel/settings', data)
    },

    updateCallPanelDepartment(id, data) {
      return base.put(`callPanel/departments/${id}`, data);
    },
    updateCallPanelGroup(id, data) {
      return base.put(`callPanel/groups/${id}`, data);
    },
    updateCallPanelSettings(id, data) {
      return base.put(`callPanel/settings/${id}`, data);
    },
    updateCallPanelPassword(id, data) {
      return base.put(`callPanel/passwords/${id}`, data);
    },

    deleteCallPanelDepartment(id) {
      return base.delete(`callPanel/departments/${id}`);
    },
    deleteCallPanelGroup(id) {
      return base.delete(`callPanel/groups/${id}`);
    },

    clearCallPanelPasswords(currentId, status, departmentId){
      return base.post(`callPanel/passwords/clear`, { currentId, status, departmentId });
    },

    activateTotem(clinicId, code) {
      return base.post(`callPanel/activateTotem`, { clinicId, code });
    },
    callNext(departmentId, professionalId, listenerId) {
      return base.post(`callPanel/next`,
        {
          departmentId,
          professionalId,
          listenerId,
          tabId: window.sessionStorage.getItem('tabId')
        });
    },
    discardAndNext(callPanelPasswordId, professionalId) {
      return base.post(`callPanel/${callPanelPasswordId}/${professionalId}/discardAndNext`, { tabId: window.sessionStorage.getItem('tabId') });
    },
    callNow(callPanelPasswordId, professionalId, listenerId) {
      return base.post(`callPanel/${callPanelPasswordId}/callNow`,
        {
          professionalId,
          listenerId,
          tabId: window.sessionStorage.getItem('tabId')
        });
    },
    finishCallPanelPasswordAttendance(callPanelPasswordId) {
      return base.post(`callPanel/${callPanelPasswordId}/finish`);
    },
    batchFinishCallPanelPasswordAttendance(ids) {
      return base.post(`callPanel/passwords/batchFinish`, ids);
    },

    // Appointment Items
    getItems(clinicId, query) {
      const config = {
        params: { query }
      }
      return base.get(`items/${clinicId}/search`, config)
    },
    getItemPrice(clinicId, type, itemId, healthPlanId, planId) {
      const config = {
        params: { type, itemId, healthPlanId, planId }
      }
      return base.get(`items/${clinicId}/price`, config)
    },
    getItemDerivatives(clinicId, itemId) {
      const config = {
        params: { clinicId }
      }
      return base.get(`items/${itemId}/derivatives`, config)
    },

    getHistoryItemsFromPatient(page, clinicId, patientId = null, perPage = null, query = null, status = null, itemType = null, professional = null, start = null, end = null, healthPlan = null) {
      const config = {
        params: { page, perPage, patientId, query, status, itemType, professional, start, end, healthPlan }
      }
      return base.get(`items/${clinicId}/patientHistory`, config)
    },
    getAvailableProcedures(itemIds) {
      const config = {
        params: { itemIds }
      }
      return base.get(`clinicProcedureRoom/availableProcedures`, config)
    },
    solvePendenciesByIds(data) {
      return base.post(`appointmentItem/solvedByIds`, data)
    },
    getPendingAppointmentItems(page, clinicId, patientId = null, filters = {}) {
      const config = {
        params: { page, patientId, filters }
      }
      return base.get(`appointmentItem/clinic/${clinicId}/pending`, config)
    },
    updateAppointmentItem(id, data) {
      return base.put(`appointmentItems/${id}`, data);
    },
    deleteAppointmentItem(id) {
      return base.delete(`appointmentItems/${id}`);
    },
    createAttendanceExam(data) {
      return base.post(`createAttendanceExam`, data);
    },
    createAttendanceProcedure(data) {
      return base.post(`createAttendanceProcedure`, data);
    },
    appointmentHasPendencies(appointmentId) {
      return base.get(`appointmentItem/appointment/${appointmentId}/hasPendencies`)
    },
    getMissingPendencies(appointmentId) {
      return base.get(`appointmentItem/appointment/${appointmentId}/getMissingPendencies`)
    },

    deleteAppointmentItemProfile(id) {
      return base.delete(`appointmentItemProfiles/${id}`);
    },

    getBeneficiaryFromAppointmentItems(clinicId, professionalId, itemIds) {
      return base.get(`onlending/beneficiary-from-appointment-items`, {
        params: { clinicId, professionalId, itemIds }
      })
    },

    // Notificações
    getUserNotifications(personId, page=1, filters={}) {
      const request = {
        params: { filters }
      }
      return base.get(`notifications/${personId}?page=${page}`, request);
    },
    createNotification(data) {
      return base.post(`notification`, data);
    },
    updateNotification(id, data) {
      return base.put(`notification/${id}`, data);
    },
    deleteNotification(id) {
      return base.delete(`notification/${id}`);
    },
    setAllAsVisualized(personId) {
      return base.post(`setAllAsVisualized/${personId}`);
    },
    sendNotificationToMany(people, notification) {
      const request = {
        params: { people, notification }
      }
      return base.post(`sendToMany`, request);
    },

    getUsers() {
      return base.get(`getUsers`);
    },

    // Nova pré-consulta
    getPatientLastPreConsultation(patientId) {
      return base.get(`getPatientLastPreConsultation/${patientId}`);
    },
    findPatientLastExam(patientId) {
      return base.get(`findPatientLastExam/${patientId}`);
    },
    //cid do ultimo atendimento
    getPatientLastCidConsultation(patientId) {
      return base.get(`getPatientLastCidConsultation/${patientId}`);
    },
    // logs
    getLogs (type, id, page=1) {
      return base.get(`getLogs/${type}/${id}?page=${page}`);
    },
    checkAppointmentPatientMessage (data) {
      const request = {
        params: { ...data }
      }
      return base.get('appointment/checkPatientMessage',request);
    },
    createLog(data) {
      return base.post(`create-log`, data);
    },
    // Pusher
    getPusherEvent(eventId){
      return base.get(`pusher/${eventId}/pusherEvent`);
    },

    // DocumentJobs
    getSignLogs(clinicId, professionalId){
      return base.get(`documentJobs/${clinicId}/${professionalId}/signLogs`);
    },
    retryDocumentJob(documentJobId){
      return base.put(`documentJobs/${documentJobId}/retry`);
    },

    // Equipamentos
    getEquipments(clinicId, id, roomId, page = 1) {
      return base.get(`equipments/${clinicId}?page=${page}`, {
        params: { id, roomId }
      });
    },
    getAllFromClinicIdAndRoomId(clinicId, roomId) {
      return base.get(`allEquipments/${clinicId}`, {
        params: { roomId }
      });
    },
    createEquipment(data) {
      return base.post(`equipments`, data);
    },
    updateEquipment(id, data) {
      return base.put(`equipments/${id}`, data);
    },
    deleteEquipment(id) {
      return base.delete(`equipments/${id}`);
    },

    createPatientHistory(data){
      const request = {
        params: { ...data }
      }
      return base.get('document/print-patient-history',request);
    },
    getLastPatientHistory(data){
      const request = {
        params: { ...data }
      }
      return base.get('patientHistory/getLastByClinicAndProfessional',request);
    },
    viewPatientHistory(id){
      return base.get(`patientHistory/${id}/viewDoc`);
    },
    unifyPatients(data) {
      return base.post(`person/unifyPatients`, data);
    },

    // APIKeys
    createApiKey(data) {
      return base.post(`apiKeys`, data);
    },
    getApiKeys(clinicId) {
      const config = {
        params: { clinicId }
      }
      return base.get(`apiKeys`, config);
    },
    destroyApiKey(apiKeyId) {
      return base.delete(`apiKeys/${apiKeyId}`);
    },

    //BV ATTENDANCE
    getBvAttendanceByAttendanceId(attendanceId) {
      return base.get(`bvAttendance/findByAttendanceId/${attendanceId}`)
    },
    createBvAttendance(data) {
      return base.post('bvAttendance', data)
    },
    updateBvAttendance(id, data) {
      return base.put(`bvAttendance/${id}`, data)
    },
    deleteBvAttendance(id){
      return base.delete(`bvAttendance/${id}`)
    },

	  getSubscription(clinicId) {
		  return base.get(`clinics/${clinicId}/subscription`)
	  },

	  getSubscriptionInvoices(clinicId, subscription_id) {
		  return base.get(`clinics/${clinicId}/subscription/${subscription_id}/invoices`)
	  },

    //TOPOGRAFIA
    getTopografiaOptions(professionalId){
      const config = {
        params: {professionalId}
      }
      return base.get('topografia', config)
    },

    storeTopografia(data) {
      return base.post('topografia', data)
    },

    deleteTopografia(id) {
      return base.delete(`topografia/${id}`)
    },

    updateTopografia(id, data) {
      return base.put(`topografia/${id}`, data)
    },

    //CERATOMETRIA
    getCeratometriaOptions(professionalId){
      const config = {
        params: {professionalId}
      }
      return base.get('ceratometria', config)
    },

    storeCeratometria(data) {
      return base.post('ceratometria', data)
    },

    deleteCeratometria(id) {
      return base.delete(`ceratometria/${id}`)
    },

    updateCeratometria(id, data) {
      return base.put(`ceratometria/${id}`, data)
    },

    // layouts
    getLayouts(clinicHealthPlanId, page=1, filters={}) {
      const request = {
        params: { filters }
      }
      return base.get(`layouts/list/${clinicHealthPlanId}?page=${page}`, request)
    },
    createLayout(data) {
      return base.post(`layouts`, data)
    },
    updateLayout(id, data) {
      return base.put(`layouts/${id}`, data)
    },
    deleteLayout(id) {
      return base.delete(`layouts/${id}`)
    },

    // layout expenses
    createLayoutExpense(data) {
      return base.post(`layoutExpenses`, data)
    },
    updateLayoutExpense(id, data) {
      return base.put(`layoutExpenses/${id}`, data)
    },
    deleteLayoutExpense(id) {
      return base.delete(`layoutExpenses/${id}`)
    },

    //BV
    getBVClinicalRecord(id) {
      return baseBV.get(`/v2/users/${id}/clinical-record`)
    },

    getHubSpotToken(clinicId) {
      return base.get(`hubspot/${clinicId}/token`)
    },

    getNfContracts() {
        return base.get(`nf-contracts`)
    },

    createNFContract(data) {
        return base.post('nf-contracts', data)
    },

    updateNFContract(data) {
        return base.put(`nf-contracts/${data.id}`, data)
    },


    getAppointmentPrintables(clinicId) {
      return base.get(`clinic/${clinicId}/appointment-printables`)
    },
    updateAppointmentPrintables(id, data) {
      return base.put(`appointment-printables/${id}`, data)
    },
    getSpSadtDateTimes(tissGuideSpSadtId) {
      return base.get(`tiss/${tissGuideSpSadtId}/spsadt-datetime`)
    },
    getItemProfessional(invoicingTissSettingId, professionalId, itemId) {
      return base.get(`tiss-guides/item-professional`, {
        params: { invoicingTissSettingId, professionalId, itemId }
      })
    },

//     Exam Package endpoints
    getExamPackages(page, clinicId, search) {
        return base.get(`exam-packages/${clinicId}`, { params: { page, search } })
    },

    createExamPackage(data) {
        return base.post(`exam-packages`, data)
    },

    updateExamPackage(data) {
        return base.put(`exam-packages`, data)
    },

    deleteExamPackage(id) {
        return base.delete(`exam-packages/${id}`)
    },

    inactivatePatient(patientId, data) {
        return base.patch(`patients/${patientId}/inactivate`, data)
    },

    reactivatePatient(patientId) {
        return base.patch(`patients/${patientId}/reactivate`)
    },
//     Selling Panel actions(Painel de Vendas)
// budgets(Orçamentos)
    createBudget(data) {
        return base.post('selling-panel/budget', data)
    },

    deleteBudget(id) {
        return base.delete(`selling-panel/budget/${id}`)
    },
    /**
     * Get budget file downstream
     * @param {Array} budgets
     * @param {String} clinic_id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getBudgetFileStream(budgets, clinic_id) {
        return baseFile.post(`selling-panel/budget/pdf`, {budgets, clinic_id})
    },

    updateBudget(data) {
        return base.put('selling-panel/budget', data)
    },

    getBudgets(clinic, page, perPage, params) {
        page = page || 1
        perPage = perPage || 5
        return base.get(`selling-panel/budgets/${clinic}?page=${page}&per_page=${perPage}`, {params})
    },

    updateBudgetStatus(budget_id, status) {
        return base.put(`selling-panel/budget/status`, {budget_id, status})
    },

    deleteBudgetItem(item) {
        return base.delete(`selling-panel/budget/delete-item/${item}`)
    },

    sendBudgetFile(dataForm, listeners) {
        return baseUpload.post(`selling-panel/budget/file`, dataForm, listeners);
    },

    deleteBudgetFile(file) {
      return base.delete(`selling-panel/budget/delete-file/${file}`)
    },

    createBudgetNote(note) {
      return base.post(`selling-panel/budget/note`, note)
    },

    getSellingPanelConfigs(clinic) {
        return base.get(`selling-panel/configs/${clinic}`)
    },

    upsertSellingPanelConfigs(clinic, data) {
        return base.post(`selling-panel/configs/${clinic}`, data)
    },

  getClinicEventCount(clinicId, date, filters) {
        return base.get(`/schedule/countEvents?clinicId=${clinicId}&date=${date}&filters=${filters}`)
    },

    getClinicOpenHours(clinicId, weekDayName) {
        return base.get(`/schedule/getClinicOpenHours?clinicId=${clinicId}&weekDayName=${weekDayName}`)
    },
    getAvailableSlots(clinicId, professional_id= '', date = '') {
        return base.get(`/schedule/getAvailableSlots?clinicId=${clinicId}&professionalId=${professional_id}&date=${date}`)
    },

    getClinicTodayEvents(clinicId, startTime = '', endTime = '', patientId = '', professional_id = '', page = 1) {
        return base.get(`/schedule/listEvents?clinicId=${clinicId}&startTime=${startTime}&endTime=${endTime}&patientId=${patientId}&professional_id=${professional_id}&page=${page}`)
  },
    
  getClinicEventsByDate(clinicId, date, startTime = '', endTime = '', filters, page = 1) {
      return base.get(`/schedule/listEvents?clinicId=${clinicId}&date=${date}&startTime=${startTime}&endTime=${endTime}&filters=${filters}&page=${page}`)
  },

  getEssilorClinicsConfigByClinic(clinicId) {
    return base.get(`/essilor/clinic/${clinicId}`)
  },

  createEssilorClinicsConfig(data) {
    return base.post(`/essilor`, data)
  },

  updateEssilorClinicsConfig(id, data) {
    return base.put(`/essilor/${id}`, data)
  },

  deleteEssilorClinicsConfig(id) {
    return base.delete(`/essilor/${id}`)
  },

  getEssilorRoomsByClinic(clinicId) {
    return base.get(`essilor/rooms/${clinicId}`)
  },
  
  getLabpacsClinicsConfigByClinic(clinicId) {
    return base.get(`/labpacs/clinic/${clinicId}`)
  },

  essilorPatientIn(data) {
    return base.put(`/essilor/rooms/patient-in`, data)
  },

  essilorCheckFreeRoom(clinicId, roomId, patientId) {
    return base.get(`/essilor/rooms/${clinicId}/check-free/${roomId}/patient/${patientId}`)
  },

  essilorPatientOut(data) {
    return base.put(`/essilor/rooms/patient-out`, data)
  },

  importEssilorExams(data) {
    return base.post(`/essilor/patient/import`, data)
  },

  createLabpacsClinicsConfig(data) {
    return base.post(`/labpacs`, data)
  },

  updateLabpacsClinicsConfig(id, data) {
    return base.put(`/labpacs/${id}`, data)
  },

  deleteLabpacsClinicsConfig(data) {
    return base.delete(`/labpacs/${data}`)
  },
  
  labpacsRequestReport(data) {
    return base.post(`/labpacs/item/request-report`, data)
    },
  sendToWorklist(data) {
    return base.post(`/labpacs/send-to-worklist`, data)
  },

  retrieveFromWorklist(data) {
    return base.post(`/labpacs/retrieve-from-worklist`, data)
  },

  updateLabpacsItem(id, data) {
    return base.put(`/labpacs/item/${id}`, data)
  },
  saveScheduleConstraint(data) {
    return base.post(`/appointment-constraints`, data)
  },
  indexScheduleConstraints(clinicId) {
    return base.get(`/appointment-constraints/${clinicId}`)
  },
  getConstraintByProfessional(clinicId, professionalId) {
    return base.get(`/appointment-doctor-constraints/${clinicId}/${professionalId}`)
  },
  getConstraintByHealthPlan(clinicId, healthPlanId, type, year, month) {
    return base.get(`/appointment-plan-constraints/${clinicId}/${healthPlanId}/${type}/${year}/${month}`)
  },
  deleteConstraint(id) {
    return base.delete(`/appointment-constraints/${id}`)
  },
  indexScheduleConstraintsFiltered(clinicId, doctors) {
    return base.get(`/appointment-constraints/${clinicId}?doctors=${doctors}`)
  },

  insertAdditionalHealthPlan(data){
    return base.post('/additional-health-plans', data);
  },

    removeAdditionalHealthPlan(id) {
        return base.delete(`additional-health-plans/${id}`);
    },

    getAdditionalHealthPlans(data) {
        const config = {
            clinic_id: data.clinic_id,
            person_id: data.person_id
        }
        return base.get('/additional-health-plans', {params: config})
    },

    updateOrientations(data) {
        const config = {
            id: data.id,
            clinic_id: data.clinic_id,
            related_id: data.related_id,
            type: data.type,
            description: data.description
        }

        return base.post('/orientations', config);
    },

    getOrientations(data){
        const config = {
            clinic_id: data.clinic_id,
            related_id: data.related_id,
            type: data.type
        }

        return base.get('/orientations', {params: config});
    },

    getBatchOrientations(data){
        const config = {
            clinic_id: data.clinic_id,
            related_ids: data.related_ids,
            type: data.type
        }

        return base.post('/orientations/get-batch-orientations', config);
    },

    sendOrientationsFile(dataForm, listeners) {
        return baseUpload.post(`orientations/file`, dataForm, listeners);
    },

    getOrientationsFiles(data){
        const config = {
            clinic_id: data.clinic_id,
            health_plan_id: data.health_plan_id || null,
            type: data.type
        }
        return base.get('orientations/file/list', {params: config});
    },

    showFile(path){
        return base.get('orientations/file', {params: {file_path: path}});
    },

    deleteFile(path, id){
        return base.delete('orientations/file', {params: {file_path: path, id}});
    },

    getBraceletPrintSettings(clinicId) {
        return base.get('print-settings/bracelet', {params: {clinicId}});
    },

    updateBraceletPrintSettings(clinicId, payload){
        return base.post('print-settings/bracelet', {clinicId, ...payload});
    },

    getHealthPlanRequiredFields(data){
        return base.get(`tiss/health-plan-required-fields`, {params: {clinic_health_plan_id: data}});
    },

    createHealthPlanRequiredFields(id){
        return base.post(`tiss/health-plan-required-fields`, {clinic_health_plan_id: id});
    },

    updateHealthPlanRequiredFields(data) {
        return base.put(`tiss/health-plan-required-fields/${data.clinic_health_plan_id}`, data);
    },

    deleteHealthPlanRequiredFields(id){
        return base.delete(`tiss/health-plan-required-fields/${id}`);
    },

    getHealthPlanRequiredFields(data){
        return base.get(`tiss/health-plan-required-fields`, {params: {clinic_health_plan_id: data}});
    },

    createHealthPlanRequiredFields(id){
        return base.post(`tiss/health-plan-required-fields`, {clinic_health_plan_id: id});
    },

    updateHealthPlanRequiredFields(data) {
        return base.put(`tiss/health-plan-required-fields/${data.clinic_health_plan_id}`, data);
    },

    deleteHealthPlanRequiredFields(id){
        return base.delete(`tiss/health-plan-required-fields/${id}`);
    },
    
    getFeatureRequestContactStatus(data) {
      return base.get('clinic/feature/request-contact/get-status', {params: data})
    },

    storeFeatureRequestContact(data) {
      return base.post('clinic/feature/request-contact', data)
    },

    updateFeatureRequestContact(id, data) {
      return base.put(`clinic/feature/request-contact/${id}`, data)
    },
    findBySusGuideIdReport(guideId) {
      return base.get(`sus/guide/guideReport/${guideId}`)
    },
    susBatchMAI(data) {
      return base.post(`susBatchMai`, data);
    },
    trackAdvertisingPatientDocs(data) {
      return base.post(`advertising/track-click`, data);
    },
    getSusBatchFiles(clinicId, batchId) {
      return base.get(`susBatchFile`, {
          params: { clinicId, batchId: batchId }
      });
    },
    uploadSusBatchFile(data) {
        return base.post(`susBatchFile`, data);
    },

    getBillPrintingPreferences(clinicId) {
        
        return base.get(`bill-printing/preferences`, { params: { clinicId }});
    },

    updateBillPrintingPreferences(data) {
        return base.post(`bill-printing/preferences`, data);
    },

    printBillReports(data) {
      return baseFile.post('bill-printing/print', data)
    },
    
    getGlosaReasons(filters={}) {
        const request = {
            params: { filters }
        }
        return base.get(`tiss/glosa-reasons`, request)
    },
    updateGlosaReason(id, data) {
        return base.put(`/tissGuide/item/${id}/glosa-reason`, data)
    },
    updateObservation(id, data) {
        return base.put(`/tissGuide/${id}/observations`, data)
    },
}

export default Api